@use '../../../../assets/css/deps' as *;

@keyframes viewerShimmer {
  0% {
    background-position: -400% 100%;
  }
  100% {
    background-position: 0 100%;
  }
}

.box {
  overflow: hidden;
  position: relative;
  border: 1px solid var(--color-border-primary);
  border-radius: var(--shape-border-radius-base);
  width: 100%;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    animation: viewerShimmer 4s infinite;
    background-image: linear-gradient(
      to right,
      var(--color-background-secondary) 0,
      var(--color-background-primary) 50%,
      var(--color-background-secondary) 100%
    );
    background-size: 400% 100%;
    transition: opacity 0.8s;
    position: relative;
    pointer-events: none;
    opacity: 0;
    z-index: 2;
  }

  &.loading {
    &:after {
      opacity: 1;
    }
  }

  &.empty {
    &:after {
      opacity: 1;
      animation-play-state: paused;
    }
  }
}
