@use '../variables' as *;
@use '../functions' as *;
@use '../react-md-config' as *;

// todo move to page component

$invoice-icon-spacing-with-text: rem(12px);
$invoice-no-icon-gap: $invoice-icon-spacing-with-text + $rmd-icon-size;
$invoice-tile-gap: calc(#{$app-padding} / 2);

$media-invoice-layout-stack: $media-desktop;
