@use '../../../../../../assets/css/deps' as *;

.box {
  padding: 10px $app-padding-mobile;
  border-bottom: 1px solid var(--color-border-primary);

  &:first-of-type {
    padding-top: $app-padding-mobile;
  }

  &:last-of-type {
    padding-bottom: $app-padding-mobile;
  }
}
