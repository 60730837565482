@use '../../../../assets/css/deps' as *;

.box {
}

.alert {
}

.body {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.text {
  margin-top: 2px;
  margin-bottom: 5px;
}

.toggleButton {
  align-self: center;
  box-shadow: 0 0 0 1px var(--color-text-primary) inset;
}

.link,
.link:visited {
  text-decoration: underline;
}
