@use 'sass:math';

@use '../../../../assets/css/deps' as *;
@use '../page';

$technology-gap: 10px;

.box {
  display: flex;
  flex-flow: wrap;
  margin: math.div(-$technology-gap, 2);
  padding: 0;
}

.tile {
  flex: 1 1;
  margin: math.div($technology-gap, 2);
  min-width: max(calc(var(--technology-tile-min-width) - 10px), 144px);
  // min-width: 144px;
  min-height: 140px;

  // @include media_interval(page.$media-viewer-column-stack, 0) {
  //   min-width: max(calc(var(--technology-tile-min-width) - 10px), 144px);
  // }
}

.technology {
  width: 100%;
  height: 100%;
  padding: 20px 14px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: background-color 0.1s ease-out;
  border: 1px solid var(--color-border-primary);
  border-radius: var(--technologies-item-border-radius, var(--shape-border-radius-base));

  // for align row height
  &:after {
    content: '';
    display: block;
    flex: 1 1;
  }
  &:hover {
    background-color: var(--color-background-secondary);
  }
  &:disabled {
    transition: background-color 0.3s ease-in;
    background-color: var(--color-background-disabled);
  }
}

.mark {
  position: absolute;
  top: 20px;
  right: 20px;

  :global {
    animation: fadeIn 0.2s forwards ease-in;
  }
}

.preview {
  overflow: hidden;
  border: var(--technology-preview-shape-border, none);
  border-radius: var(--technology-preview-shape-border-radius, 50%);

  & > img {
    width: var(--technology-preview-size-width, $technology-img-size);
    height: var(--technology-preview-size-height, $technology-img-size);
  }
}

.title {
  margin-top: 9px;
}

.note {
  margin-top: 5px;
  color: var(--color-text-primary);
}
