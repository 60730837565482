@use '../../assets/css/deps' as *;

.box {
  padding: 8px 16px;
  min-height: rem(32px);
  border-radius: 100px;
  @include rmd-theme(color, text-secondary-on-background);

  &:not(:first-child) {
    margin-left: 35px;
  }
}

.label {
  font-size: var(--button-2-font-size);
}
