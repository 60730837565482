@use '../../assets/css/deps' as *;

$enter-duration: 300ms;
$exit-duration: 200ms;

.inner {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: $app-padding;

  @include media_interval($media-desktop, 0) {
    min-height: calc(100vh - var(--p-header-height));
  }
}

.appear,
.enter {
  opacity: 0;
}

.appearActive,
.enterActive {
  transition: opacity $enter-duration linear;
  opacity: 1;
}

.exit {
  transition: opacity $exit-duration linear;
  opacity: 1;
}

.exitActive {
  opacity: 0;
}
