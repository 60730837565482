@use '../../assets/css/deps' as *;

// container
.box {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: var(--shape-border-radius-base);
  width: 100%;

  &.dismissible {
    padding-right: 40px;
  }

  &.error,
  &.warning {
    .iconAlign {
      margin-top: 3px;
    }
  }

  &.standard {
    &.info {
      @include rmd-theme(background-color, background);
    }

    &.error {
      //@include rmd-theme(color, error);
      background-color: rgba(var(--color-ui-error-rgb), 0.05);
    }

    &.warning {
      //@include rmd-theme(color, warning);
      background-color: rgba(var(--color-ui-warning-rgb), 0.05);
    }

    &.success {
      //@include rmd-theme(color, success);
      background-color: rgba(var(--color-ui-success-rgb), 0.05);

      .icon {
        @include rmd-theme(color, success);
      }
    }
  }

  &.outlined {
    @include rmd-theme(background-color, surface);

    &.info {
      border: 1px solid var(--color-text-primary);
      //@include rmd-alert-theme-update-var(color, var(--color-text-primary)); // TODO need to be a child of .rmd-toast__message
    }

    &.error {
      border: 1px solid rmd-theme-var(error);
      //@include rmd-alert-theme-update-var(color, rmd-theme-var(error));
    }

    &.warning {
      border: 1px solid rmd-theme-var(warning);
      //@include rmd-alert-theme-update-var(color, rmd-theme-var(warning));
    }

    &.success {
      border: 1px solid rmd-theme-var(success);
      //@include rmd-alert-theme-update-var(color, rmd-theme-var(success));

      .icon {
        @include rmd-theme(color, success);
      }
    }
  }

  &.filled {
    &.info {
      // TODO we don't have correspond RMD color for "info" type
      @include rmd-theme(background-color, text-secondary-on-background);
      @include rmd-theme(color, on-primary);
    }

    &.error {
      @include rmd-theme(background-color, error);
      @include rmd-theme(color, on-error);
    }

    &.warning {
      @include rmd-theme(background-color, warning);
      @include rmd-theme(color, on-warning);
    }

    &.success {
      @include rmd-theme(background-color, success);
      @include rmd-theme(color, on-success);
    }

    &.customWarning {
      background-color: rgba(var(--color-ui-warning-rgb), 0.2);
      color: var(--color-text-primary);

      div {
        line-height: 15px;
      }

      .head {
        display: flex;
        flex-direction: column;
      }
    }

    :global(.rmd-typography),
    .icon,
    .close {
      color: currentColor;
    }
  }
}

// heading
.head {
  display: inline-flex;
  align-items: flex-start;
}

.iconOffset {
  @include rmd-icon-theme-update-var(
    text-spacing,
    rem(10)
  ); // TODO move to CSS utilities layer https://tailwindcss.com/docs/adding-custom-styles#adding-custom-utilities
}

.label {
  align-self: center;
}

.text {
  max-width: 370px;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
}
