.editable {
  border: 1px dashed gray;
  padding: 4px 20px 4px;
  border-radius: 6px;
}

.reset {
  padding: 4px 8px;
  cursor: pointer;
}

.editable-node {
  display: flex;
}

.placeholder {
  color: var(--color-text-secondary);
}
