@use '../../../assets/css/deps' as *;

.box {
  padding-left: $app-padding;
  padding-right: $app-padding;

  @include for-phone-only() {
    padding-left: $app-padding-mobile;
    padding-right: $app-padding-mobile;
  }
}

.list {
  min-height: 120px;
}
