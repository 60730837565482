@use '../../deps' as *;

@forward 'variables';

// overridden classes
.lil-gui {
  border: 2px solid #{$neutral-darkest};

  .controller {
    gap: 5px;
  }

  .widget input,
  .widget button,
  .slider {
    border: 2px solid #{$neutral-darkest};
  }

  .widget input:hover,
  .widget button:hover,
  .slider:hover {
    border: 2px solid #{$dark};
  }

  .widget input:focus {
    border: 2px solid #{$dark-blue};
  }

  .display {
    border: 2px solid #{$neutral-darkest};
  }

  .number .name,
  .color .name {
    // to don't squeeze inputs
    max-width: 45%;
    overflow: hidden;
  }
}
