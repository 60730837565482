@use '../../deps' as *;

.lil-gui {
  // core, panel label, input label
  --font-family: #{$rmd-typography-font-family};
  --font-size: 14px;
  --text-color: #{$dark};

  // panel
  --background-color: #{$white};
  --spacing: 5px;

  // panel label
  --title-background-color: #{$neutral-subtlest};
  --title-text-color: #{$dark};

  // input label
  --name-width: 45%;

  // input base
  --input-font-size: 14px;
  --widget-height: 24px;
  --widget-border-radius: #{rem(3)};
  --widget-color: #{$neutral-subtlest};
  --number-color: #{$dark};
  --string-color: #{$dark};

  // input states
  --hover-color: #{$neutral-subtle};
  --focus-color: #{$white};

  // input slider
  --slider-input-width: 45%;
  --slider-input-min-width: 0;
  --slider-knob-width: 13px;

  // input color
  --color-input-width: 60%;
  --color-input-min-width: 0;
}
