@use '../../assets/css/deps' as *;

.popup {
}

.header {
  //position: relative;
  //margin-left: auto;
  //margin-right: auto;
  //@include rmd-dialog-theme(max-width, min-width);

  &:global(.rmd-dialog__header) {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 36px;
  }
}

.close {
  &:global(.rmd-button) {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
