@use '../../../../assets/css/deps' as *;

.spec {
  &:global(.rmd-typography) {
    flex-shrink: 0;
    padding-left: 5px;
  }
}

.loader {
  padding: 0;
}

.displayLabel {
  position: absolute;
}

.mark {
  padding: 6px 10px;
  display: inline-block;
  font-size: var(--font-size-body-xs);
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color-ui-primary-subtle);
  background-color: var(--color-ui-primary-subtlest);
  border-radius: 50px;
}
