@use '../../../../../assets/css/deps' as *;

.box {
}

.unitsBox {
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
}

.unitsLabel {
  flex-shrink: 0;
  line-height: rem(16px);
  margin-right: 12px;
  margin-bottom: 0;
}

.units {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
}

.unit {
  margin: 6px;

  :global(.rmd-toggle) {
    @include rmd-button-theme-update-var(icon-size, rem(16));
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.unitName {
  font-size: var(--font-size-body-xs);
  margin-left: 4px;
}

.actions {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
