@use '../../../../assets/css/deps' as *;
@use '../page';

.row {
  position: relative;
  z-index: 1;
  padding: $app-padding-half $app-padding-mobile;
  overflow: hidden;
  border-radius: var(--shape-border-radius-base);
  border: 1px solid var(--color-border-primary);
  margin-bottom: page.$line-items-column-gap;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include for-phone-only() {
    padding: $app-padding-half;
  }
}

.btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  @include rmd-theme(background-color, surface);
  transition: background-color 0.1s ease-out;

  &:disabled,
  &:hover {
    background-color: var(--color-background-secondary);
  }

  &:disabled {
    transition: background-color 0.3s ease-in;
  }
}

.grid {
  position: relative;
  pointer-events: none;
  @include rmd-button-theme(min-height, icon-size);
}

.status {
  display: inline-flex;
  align-items: center;

  @at-root .row:hover & {
    display: none;
  }
}

.actions {
  display: flex;

  //& > * {
  //  margin-left: 10px;
  //}
}

.action {
  pointer-events: auto;
}

.row:not(:hover) .action {
  @include rmd-utils-keyboard-exclude(true) {
    display: none;
  }
}

.delete {
  right: -10px;
}

.warnings {
  display: inline-flex;
  flex-direction: row-reverse;
  position: absolute;
  right: 3px;
  bottom: 1px;

  .error {
    z-index: 1;

    & + .warning {
      margin-right: -10px;
    }
  }
}

// product
.product {
  &.active,
  &:hover {
    .files {
      @include rmd-chip-theme-update-var(solid-background-color, var(--color-background-primary));
    }
  }
}

// product details
.fields {
  position: relative;
  pointer-events: none;
}

.file {
  &:global(.rmd-chip) {
    font-size: var(--font-size-body-xs);
  }
}

.value {
  color: var(--color-text-primary);
}
