.box {
  height: 100%;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
}

.title {
  margin-bottom: 4px;
}
