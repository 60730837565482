@use '../../../../assets/css/deps' as *;

.box {
  display: flex;
  margin-top: 9px;
}

.action {
  &.duplicate {
  }

  &.delete {
  }

  &.download {
    display: grid;
    grid-column-gap: 5px;
    grid-auto-flow: column;
    align-items: center;
    margin-right: 20px;

    @include rmd-icon-theme-update-var(text-spacing, rem(3px));

    &.dot {
      &:after {
        content: '';
        display: inline-block;
        width: 3px;
        height: 3px;
        grid-column-start: 4;
        @include rmd-theme(background-color, text-primary-on-background);
        border-radius: 50%;
      }
    }
  }
}

.link {
  @include letter-spacing('tracked');
}
