@use '../../../../assets/css/deps' as *;

.box {
  grid-template-columns: repeat(2, 1fr);
  min-height: 60px;

  //&:empty {
  //  height: 60px;
  //}
}

.text {
  margin-top: 2px;
}

.cell {
  display: inline-flex;
  flex-direction: column;
}

.cost {
  margin-top: 5px;
  margin-left: 26px;
}

.note {
  margin-top: 3px;
  margin-bottom: 1px;
  margin-left: 26px;
}
