@use '../../../assets/css/deps' as *;

.box {
}

.quote {
  @include rmd-icon-theme-update-var(text-spacing, rem(10px));
}

.counter {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  //background-color: var(--color-on-ui-primary);
  @include rmd-button-theme(background-color, color);
  letter-spacing: normal;

  &:before {
    content: attr(data-place) ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-55%, -50%);
    @include rmd-button-theme(color, background-color);
  }

  &.counterBig {
    padding: 6px;
    border-radius: var(--shape-border-radius-base);
    width: auto;
    height: auto;

    &:before {
      position: relative;
      top: initial;
      left: initial;
      transform: none;
    }
  }
}
