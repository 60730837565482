@use '../../../../assets/css/deps' as *;

.box {
}

.alert {
  &:global(.base-paddings) {
    padding-top: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
  }
}

.text {
  color: inherit;
  line-height: rem(18px);
}
