@use '../../assets/css/deps' as *;

.list {
  --gap: #{rem(4)};

  padding: 0;
  margin: 0;
  display: grid;
  align-content: flex-start;
  row-gap: var(--gap);
}

.box {
  display: flex;
  align-items: baseline;
}

.key {
  white-space: pre; // save whitespace, but no wrap on new lines
  //margin-right: rem(4);
}

.value {
  white-space: pre-line;
}

.editValue {
}
