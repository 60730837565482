@font-face {
  font-family: 'ProximaNova';
  src: url('https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3')
      format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
