//.font-normal {
//  font-weight: 400;
//}
//
//.font-semibold {
//  font-weight: 600;
//}
//
//.font-bold {
//  font-weight: 700;
//}

.rmd-typography--regular {
  font-weight: 400;
}

.rmd-typography--medium {
  font-weight: 500;
}

.rmd-typography--semi-bold {
  font-weight: 600;
}

.rmd-typography--bold {
  font-weight: 700;
}

// rmd-typography--* classes are used by Typography component
// it's placed here because @mixin react-md-typography puts them before button styles,
// but utility classes must come after all styles
