@use '../../deps' as *;

.rmd-dialog-container {
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  text-align: center;

  &:after {
    content: '';
    display: inline-block;
    height: 100%;
    min-height: inherit;
    vertical-align: middle;
  }
}

.rmd-dialog:not(.rmd-dialog--full-page) {
  display: inline-block;
  vertical-align: middle;
  margin-top: 50px;
  margin-bottom: 50px;
  max-height: initial;
  border-radius: var(--shape-border-radius-base);
  text-align: left;
  max-width: $rmd-dialog-min-width;
}

.rmd-dialog__content {
  overflow: initial;
}

// custom
.rmd-dialog-container--scrollable {
  pointer-events: auto;
}
