@use '../../assets/css/deps' as *;

$button-width: 30px;

.box {
  &:global(.rmd-text-field-container--autosize) {
    &:before {
      order: 2;
    }
  }

  &.controls {
    :global(.rmd-text-field) {
      @include rmd-form-theme-update-var(text-padding-left, $button-width);
      @include rmd-form-theme-update-var(text-padding-right, $button-width);
    }

    &:global(.rmd-text-field-container--autosize):before {
      min-width: 30px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.button {
  &:global(.rmd-button) {
    box-shadow: none;
    width: $button-width;

    &:before {
      background-color: transparent;
    }

    &:not(&:global(.rmd-button--disabled)):hover {
      svg {
        @include rmd-theme(color, primary);
      }
    }
  }

  &:global(.rmd-button--disabled) {
    svg {
      @include rmd-button-theme(color, outline);
    }
  }
}

.decrement {
  order: 1;
  z-index: 1;
}

.increment {
  order: 3;
  z-index: 1;
}
