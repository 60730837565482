@use '../deps/functions' as *;

// emulation for https://caniuse.com/flexbox-gap
// @link https://coryrylan.com/blog/css-gap-space-with-flexbox
.emulated-flex-gap {
  --gap: #{rem(10)};
  --h-gap: var(--gap);
  --v-gap: var(--gap);
  display: inline-flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--v-gap)) 0 0 calc(-1 * var(--h-gap));
  width: calc(100% + var(--h-gap));
}

.emulated-flex-gap > * {
  margin: var(--v-gap) 0 0 var(--h-gap);
}

// fix for full width children
.emulated-flex-gap > .w-full {
  width: calc(100% - var(--h-gap));
}
