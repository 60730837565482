@use '../../assets/css/deps' as *;

.box {
  overflow: hidden;
  border-radius: var(--shape-border-radius-base);
  @include rmd-utils-full-screen(absolute);
  pointer-events: none;
  z-index: -1;
  transition: z-index 0s 0.3s;

  :global(.dropzone-page--active) &:not(.disabled) {
    pointer-events: auto;
    transition: z-index 0s 0s;
    z-index: 50;
  }

  &:before {
    @include rmd-utils-pseudo-element();
    border: 1px dashed var(--color-text-primary);
    background-color: var(--color-background-primary);
    transition: opacity 0.3s;
    opacity: 0;

    :global(.dropzone-page--active) & {
      opacity: 0.75;
    }
  }

  :global(.dropzone-page--active) &.active:before {
    opacity: 0.9;
  }

  //&:focus {
  //  transition: z-index 0s 0s;
  //  z-index: 50;
  //
  //  &:before {
  //    opacity: 0.9;
  //  }
  //}
}

.hint {
  @include rmd-utils-absolute-centered;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;

  :global(.dropzone-page--active) & {
    opacity: 1;
  }

  .active & {
    color: var(--color-ui-success);
  }
}
