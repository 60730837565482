@use '../../../assets/css/deps' as *;

.grid {
  display: flex;
  justify-content: flex-end;

  @include media_interval(0, $media-desktop) {
    flex-direction: column;
  }
}

.left {
  flex: 1 1;
}
