@use '../../../../assets/css/deps' as *;
@use '../page';

.box {
  &:global(.base-paddings) {
    padding-top: $app-padding-mobile;
    padding-bottom: $app-padding-mobile;
  }
}

.expand {
  @include rmd-card-theme-update-var(background-color, var(--color-background-secondary));
}

.head {
}

.body {
  --record-list-gap: #{rem(8)};

  // if we set horizontal padding in CardContent, it will brake animation,
  // ExpansionPanel does not allow passing props for useCollapseTransition to set correct animation
  &:global(.base-paddings) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.label {
  &.empty {
    &:after {
      content: '';
      display: inline-block;
      width: 0.4em;
      border-bottom: 0.1em solid currentColor;
      border-radius: 0.08em;
      margin-left: 0.48em;
    }
  }
}

.expansion-icon {
  color: var(--color-text-secondary);
  rotate: 180deg;
}
