@use 'sass:color';
@use 'sass:math';

@use '../functions' as *;

//=======================
// Точки для media-queries
//=======================
$media-huge-desktop: 1440px; // desktopLargeMinWidth: 1440,
$media-big-desktop: 1280px;
$media-desktop: 1024px; // desktopMinWidth: 1024,
$media-tablet-landscape: 768px;
$media-tablet-portrait: 600px; // tabletMinWidth: 600,

/*
  There are three critical breakpoints we have in js: desktopLargeMinWidth, desktopMinWidth, tabletMinWidth.
  Because breakpoints are hardcoded in react-md we can't add new ones. Search for useAppSize usages.

  Range: mobile --- 600 --- tablet --- 1024 --- desktop --- 1440 --- huge-desktop
 */

//=======================
// Grid
//=======================
$grid-max-width: 1920px;
$grid-gutter-width-mobile: 15px;
$grid-gutter-width-landscape: 25px;
$grid-gutter-width-wide: 40px;

$app-padding: 20px;
$app-padding-half: math.div($app-padding, 2);
$app-padding-mobile: 15px;

//=======================
// Fonts
//=======================
$font-weights: (
  'regular': 400,
  'semibold': 600,
  'bold': 700,
);

$letter-spacings: (
  'default': 0.3px,
  'tracked': 0.003em,
  'wide': 1px,
);

//=======================
// Цвета
//=======================
// grayscale
$white: #ffffff;
$black: #000000;
$dark: #121117;
$neutral-subtlest: #f4f6f9;
$neutral-subtle: #e4e9f0;
$neutral-dark: #c0c9d2;
$neutral-darkest: #7f91a2;

// chromatic
$green: #4daf00;
$green-subtle: #d4e2c9;

$blue: #0057d7;
$blue-subtle: #b7cef0;
$blue-subtlest: #f3f8ff;
$blue-dark: #003d96;

$dark-blue: #0c2953;
$dark-blue-subtle: #224170;

$red: #ff5b4a;
$red-subtle: #fce6e6;

$yellow: #ffc107;
$yellow-subtle: #feebc8;
$yellow-dark: #eab408;
$yellow-darkest: #7d5e00;

//=======================
// Static defaults
//=======================

//$debug: true;
$debug: false;
$debug-color: lightgoldenrodyellow;

// fonts
$font-size-heading-1: rem(48.8px); // not currently used in the app, but is included in the design system
$font-size-heading-2: rem(30px); // not currently used in the app, but is included in the design system
$font-size-heading-3: rem(24px); // pages/popup titles, section headers
$font-size-heading-4: rem(18.76px); // box, sidebar and tile titles
$font-size-heading-5: rem(15px); // box labels: alert, technology, model, specification section, etc...
$font-size-heading-6: rem(
  13px
); // small labels (model viewer window), form errors, sidebar pricing values, PP prices, model status in column

$font-size-body-lg: rem(18.75px); // not currently used in the app, but is included in the design system

// default for input font size (fields, selects, colors), dropdown options
// medium plain text: alerts text, login/signup message, terms description, some messages
$font-size-body-md: rem(15px);

// small plain text: notes, hints, table headers, form labels, pricing list labels (sidebar, rates),
// small text in table cells, bulk prices cells, CNC features, price corrections labels, invoice tiles text, cart total box
$font-size-body-sm: rem(13px);

// label in iqt banner, tbd labels, addition inside the input field,
// product fields in column, other small labels
$font-size-body-xs: rem(12px);

$button-1-font-size: rem(13px);
$button-2-font-size: rem(10.24px);

//=======================
// brand: buttons, links
//=======================
$primary-color: $blue;
$primary-color-hover: $blue-subtle;
$primary-color-active: $blue-dark;

$secondary-color: $dark-blue-subtle;
$secondary-color-hover: $dark-blue-subtle;
$secondary-color-active: $dark-blue;

//=======================
// services: alerts, states
//=======================
$info-color: $dark-blue-subtle; // todo incomplete palette, discuss with designer
$info-color-hover: color.adjust($dark-blue-subtle, $lightness: 3%);
$info-color-active: $dark-blue;

$success-color: $green;
$success-color-hover: $green-subtle;
$success-color-active: color.adjust($success-color, $lightness: -5%);

$warning-color: $yellow;
$warning-color-hover: $yellow-subtle;
$warning-color-active: $yellow-dark;

$error-color: $red;
$error-color-hover: $red-subtle;
$error-color-active: color.adjust($error-color, $lightness: -30%);

//=======================
// Animations
//=======================
$animation-easing: cubic-bezier(0.61, 1, 0.88, 1);
$animation-duration: 0.6s;

//=======================
// Other
//=======================
$technology-img-size: 50px;
$technology-tile-min-width: 25%;
