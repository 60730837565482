@use '../../../../../assets/css/deps' as *;

.box {
  height: 100%;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
  overflow: hidden;
}

.half {
  padding-right: 10px;
  display: flex;
  flex-direction: column;
}

// scale
.halfRight {
  display: flex;
  align-items: center;
  position: relative;
}

.title {
  margin-bottom: 4px;
}

.button-box {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  flex-shrink: 0;
}

.divider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -13px;
  width: 1px;
  background-color: var(--color-border-primary);
}

.converter {
  margin-left: rem(4);
}
