@use '../../../../assets/css/deps' as *;

.column {
  flex-shrink: 0;
  flex-basis: min-content;

  @include media_interval(0, $media-desktop) {
    margin-top: $app-padding-mobile;
  }

  @include media_interval($media-desktop, 0) {
    margin-left: $app-padding;
  }

  @include media_interval($media-desktop, $media-big-desktop) {
    min-width: 265px;
  }

  @include media_interval($media-big-desktop, 0) {
    min-width: $order-sidebar-wide-min-w;
  }
}

.box {
}
