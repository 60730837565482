@use '../../assets/css/deps' as *;

.notice {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
