@use 'sass:math';

@use '../../../assets/css/deps' as *;
@use './page';

.layout {
  flex-grow: 1; // todo, fix for safari
}

.columns {
  //min-height: 100%; // todo, fix for safari
  display: grid;
  grid-gap: $app-padding-half;
  grid-template-areas: 'line-items' 'widget' 'calculation';

  @include media_interval(page.$media-calculation-column-stack, page.$media-line-items-column-stack) {
    grid-template-areas: 'line-items line-items' 'widget calculation';
    grid-template-columns:
      auto
      minmax(page.$calculation-box-min-width, calc(min(#{page.$calculation-box-max-width}, 40%)));
  }

  @include media_interval(page.$media-line-items-column-stack, 0) {
    grid-template-areas: 'line-items widget calculation';
    grid-template-columns:
      minmax(
        page.$line-items-column-min-width,
        calc(
          min(
            #{page.$line-items-column-max-width},
            #{deunit(page.$line-items-column-max-width)} / #{deunit($grid-max-width)} * 100vw
          )
        )
      )
      auto
      minmax(page.$calculation-box-min-width, calc(min(#{page.$calculation-box-max-width}, 27%)));
  }
}

.widget {
  grid-area: widget;
  position: relative;
  border: 1px solid var(--color-border-primary);
  display: grid;
  grid-gap: $app-padding;
  grid-template-columns: auto;
  grid-template-areas:
    'viewer'
    'specification';
  align-content: flex-start;

  @if page.$debug-page {
    min-height: 1400px;
  }

  @include media_interval(page.$media-viewer-column-stack, 0) {
    grid-template-areas: 'viewer specification';
    grid-template-columns:
      minmax(page.$viewer-box-min-width, calc(min(#{page.$viewer-box-max-width}, 33%)))
      auto;
  }

  @include media_interval(page.$media-calculation-column-stack, 0) {
    &:global(.base-paddings) {
      padding-bottom: 107px;
    }
  }

  @include for-phone-only() {
    grid-gap: $app-padding-mobile;
  }
}
