@use '../../../assets/css/deps' as *;

.layout {
}

.grid {
  display: flex;
  justify-content: flex-end;

  @include media_interval($media-invoice-layout-stack, 0) {
    align-items: flex-start;
  }

  @include media_interval(0, $media-invoice-layout-stack) {
    flex-direction: column;
  }
}

.left-container {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  gap: $invoice-tile-gap;
}

.left {
  flex: 1 1;
  display: grid;
  gap: $invoice-tile-gap;
  grid-template-columns: minmax(0, 50%) minmax(0, 50%);
  grid-auto-flow: dense;
}

.row {
  grid-column: span 2;
}

.rowHalf {
  @include media_interval(0, $media-invoice-layout-stack) {
    grid-column: span 2;
  }
}

.prices {
  @include media_interval($media-invoice-layout-stack, 0) {
    float: right;
    min-width: 400px;
  }
}

:global(.invoice-no-icon-gap) {
  padding-left: $invoice-no-icon-gap;
}
