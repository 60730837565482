@use '../../../../assets/css/deps' as *;
@use '../page';

.box {
  padding-top: $app-padding-half;
  padding-bottom: $app-padding-half;
  background-color: var(--color-background-primary);
}

.inner {
  border: 1px dashed var(--color-border-primary);
  border-radius: var(--shape-border-radius-base);
  transition: background-color 0.1s ease-out;

  &.progress {
    background-color: var(--color-background-secondary);
  }
}
