@use '../../../../assets/css/deps' as *;

.box {
}

.grid {
  grid-template-columns: repeat(3, 1fr);

  &:before {
    content: '';
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
  }
}
