@use '../../../../../assets/css/deps' as *;

.box {
}

.alert {
  margin-top: 10px;

  &:first-of-type {
    margin-top: 0;
  }
}

.text {
}

.list {
  margin: 0;
  padding-left: 0;
  //padding-left: 30px;
}

.error {
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.help {
  align-self: flex-start;
  margin-top: $app-padding-mobile;
}

.message {
  .messageText {
    margin-right: 5px;
  }
}
.switchLabel {
  &:global(.rmd-label) {
    margin-left: 8px;
  }
}

.switch:global(.rmd-switch-container) {
  padding: 0;
  margin-top: 10px;
  margin-bottom: 21px;
}

.track:global(.rmd-switch) {
  width: 29px;
  height: 16px;
}

.trackActive:global(.rmd-switch) {
  @include rmd-theme(background-color, success);
}

.ball:global(.rmd-switch__ball) {
  width: 12px;
  height: 12px;
  &:after {
    width: 12px;
    height: 12px;
    @include rmd-theme(background-color, text-secondary-on-background);
  }
}

:global(.rmd-switch__input:checked + .rmd-switch__ball).ball:after {
  @include rmd-theme(background-color, on-success);
}

.messageHelpIcon {
  cursor: pointer;
  width: auto;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
}

.warning-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
