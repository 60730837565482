@use '../../../../../assets/css/deps' as *;

.box {
  bottom: 15px;
  left: 15px;

  span:first-child:only-child {
    display: none;
  }
}

.btn:global(.rmd-button) {
  @include rmd-button-theme-update-var(icon-size, rem(30px));

  &.active {
    @include rmd-theme(background-color, background);
  }
}

.title {
  margin-right: 5px;

  @at-root :global(.viewer-dark-mode-on) & {
    @include rmd-theme(color, surface);
  }
}
