@use '../../../../assets/css/deps' as *;

.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.image {
  width: 40px;
  height: 40px;
}

.emodji {
  font-size: 40px;
}
