@use '../../assets/css/deps' as *;

.additional_contacts-container {
  width: 100%;
  padding: 8px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  border-radius: var(--shape-border-radius-base);
  border: 1px solid var(--color-border-primary);
}

.additional_contacts-container_error {
  border: 1px solid var(--color-ui-error);
}

.email_chip {
  $margin-vertical: 6px;

  height: calc(var(--font-size-body-sm) + #{$margin-vertical} * 2);
  padding: $margin-vertical 8px;
  color: var(--color-text-secondary);
  background-color: var(--color-background-disabled);
  border: 1px solid var(--color-border-primary);
}

.email_input {
  color: var(--color-text-secondary);
  flex-grow: 1;
  flex-shrink: 1;

  &:global(.rmd-text-field-container) {
    @include rmd-form-theme-update-var(text-height, auto);
  }
}

.remove_contact-button {
  cursor: pointer;
}
