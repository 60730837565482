@use '../../../../../assets/css/deps' as *;

.box {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
}

.switch:global(.rmd-switch-container) {
  padding: 0;
  flex-direction: row-reverse;
}

.ball:global(.rmd-switch__ball) {
  &:after {
    @include rmd-theme(background-color, text-hint-on-background);
  }
}

:global(.rmd-switch__input:checked + .rmd-switch__ball).ball:after {
  @include rmd-theme(background-color, text-primary-on-background);
}

.label {
  margin-right: 5px;

  &.dark {
    @include rmd-theme(color, surface);
  }
}
