@use '../../../assets/css/deps' as *;

.layout {
}

.grid {
  display: flex;

  @include media_interval(0, $media-desktop) {
    flex-direction: column;
  }
}

.left {
  flex: 1 1;
  overflow: auto;
}

.actions {
  &:global(.emulated-flex-gap) {
    --gap: #{$app-padding-mobile};
  }
}
