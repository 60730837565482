@use '../../../../assets/css/deps' as *;

// base
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: dashed var(--color-border-primary);
}

.img {
  max-width: 130px;
  color: var(--color-border-primary);
}

// single
.single {
  &.box {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .text {
    margin-top: 30px;
    margin-bottom: 20px;
    max-width: 200px;
  }
}

// multiple
.multiple {
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 14px;
  margin-bottom: 14px;

  .img {
    max-width: 84px;
  }

  .text {
    line-height: rem(18px);
  }
}
