@use '../../assets/css/deps' as *;

.layout {
  min-height: 80vh;
  display: flex;
  align-items: center;
}

.box {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  @include rmd-dialog-theme(max-width, min-width);
}
