@use 'sass:math';

@use '../../../../../../assets/css/deps' as *;

$dot-gap: 3px;
$dot-gap-percent: math.div(floor(100px - math.div(100px, $dot-gap)), 1px) * 1%;
$dot-color: var(--color-text-secondary);

.box {
}

.file {
  position: relative;
  margin-left: 42px;

  &:before {
    content: '';
    position: absolute;
    bottom: calc(100% - 30px);
    right: calc(100% + 5px);
    width: 17px;
    height: 101%;
    //height: calc(100% + 1px);
    background: linear-gradient(
          to right,
          transparent 0%,
          transparent $dot-gap-percent,
          $dot-color $dot-gap-percent,
          $dot-color 100%
        )
        $dot-gap bottom repeat-x,
      linear-gradient(
          to top,
          rgba(0, 0, 0, 0.1) 0%,
          rgba(0, 0, 0, 0.1) $dot-gap-percent,
          $dot-color $dot-gap-percent,
          $dot-color 100%
        )
        left -#{$dot-gap} repeat-y;
    background-size: $dot-gap 1px, 1px $dot-gap;
  }

  &:first-of-type {
    &:before {
      height: 20px;
    }
  }
}

.row {
  padding-top: 10px;
}
