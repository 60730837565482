@use '../../deps' as *;

.rmd-list-item--clickable {
  @include rmd-states-theme-update-var(hover-color, var(--color-background-secondary));

  &:before {
    z-index: -1;
    transition: none;
  }
}

.rmd-list-item--email {
  border-bottom: 1px solid var(--color-border-primary);
}
