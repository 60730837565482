.content {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 12px;
  justify-items: center;
}

.folded-hands {
  font-size: 72px;
}

.modal {
  padding: 32px 56px;
}
