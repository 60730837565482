@use '../../../../assets/css/deps' as *;

.column {
  flex-shrink: 0;
  flex-basis: min-content;

  @include media_interval(0, $media-invoice-layout-stack) {
    margin-top: $invoice-tile-gap;
  }

  @include media_interval($media-invoice-layout-stack, 0) {
    margin-left: $app-padding;
  }

  @include media_interval($media-invoice-layout-stack, $media-big-desktop) {
    min-width: 265px;
  }

  @include media_interval($media-big-desktop, 0) {
    min-width: $order-sidebar-wide-min-w;
  }
}

.box {
}

.status {
  padding: 12px 32px;
  min-height: 1rem;
  display: inline-block;
  border-radius: 0 0 6px 6px;
  transition: background-color 0.2s, color 0.2s;

  &.info {
    background-color: var(--color-border-primary);
    @include rmd-theme(color, text-secondary-on-background);
  }

  &.warning {
    @include rmd-theme(background-color, warning);
    @include rmd-theme(color, on-warning);
  }

  &.success {
    @include rmd-theme(background-color, success);
    @include rmd-theme(color, on-success);
  }

  &.error {
    @include rmd-theme(background-color, error);
    @include rmd-theme(color, on-error);
  }
}
