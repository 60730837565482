.modal {
  position: relative;
  display: flex;
}

.modalHeader,
.modalContent,
.modalFooter {
  display: flex;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
}

.crossButton {
  position: absolute;
  top: 10px;
  right: 10px;
  min-width: 20px;
  min-height: 20px;
  padding: 0;
  color: var(--color-text-secondary);
}

.confirmationButton {
}
