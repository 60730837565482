@use '../../../../assets/css/deps' as *;

.box {
}

.table {
  //margin-left: 1px;
  //margin-right: 1px;
  //margin-bottom: 1px;
  //max-width: calc(100% - 2px);
  //width: 100%;
  border-collapse: initial;
  //border-style: hidden; /* hide standard table (collapsed) border */

  .head {
    td {
      padding: 0 $app-padding;
      background-color: var(--color-background-primary);
    }
  }

  .body {
    td {
      padding: $app-padding-half $app-padding;
      border-right: 1px solid var(--color-border-primary);
      border-bottom: 1px solid var(--color-border-primary);
    }
  }
}

.body {
  //box-shadow: 0 0 0 1px var(--color-border-primary); /* this draws the table border  */
  //box-shadow: inset 0 0 0 1px var(--color-border-primary); /* this draws the table border  */
  //border-radius: var(--shape-border-radius-base);

  tr:first-child {
    td {
      border-top: 1px solid var(--color-border-primary);
    }

    td:first-child {
      border-top-left-radius: var(--shape-border-radius-base);
    }
    td:last-child {
      border-top-right-radius: var(--shape-border-radius-base);
    }
  }

  tr:last-child {
    td:first-child {
      border-bottom-left-radius: var(--shape-border-radius-base);
    }
    td:last-child {
      border-bottom-right-radius: var(--shape-border-radius-base);
    }
  }
}

.row {
}

.cell {
  text-align: center;
  min-width: 95px;
  width: 1%;
}

.sticky {
  position: sticky;
  left: 0;
}

.quantity {
  border-left: 1px solid var(--color-border-primary);
}

.quantity,
.active,
.price:hover {
  background-color: var(--color-background-secondary);
}

.price:hover {
  cursor: pointer;
}
