@use 'sass:map';
@use '../variables' as *;

@mixin letter-spacing($spacing: 'default') {
  @if not map.has-key($letter-spacings, $spacing) {
    @error "Invalid $letter-spacings key (#{$spacing})";
  }

  letter-spacing: map.get($letter-spacings, $spacing);
}

/* Usage:
    @include letter-spacing();
    @include letter-spacing("tracked");
*/
