@use '../../assets/css/deps' as *;

.box {
  @include rmd-utils-full-screen;
}

.loader {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
