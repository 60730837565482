@use '../../assets/css/deps' as *;

.img {
  max-width: 130px;
  color: var(--color-border-primary);
  margin-bottom: 24px;
}

.wrapper {
  position: absolute;
  top: calc(100vh / 4);
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-self: center;
  text-align: center;
  min-width: 405px;
  background-color: $white;
  margin: 0 auto;
  border-radius: var(--shape-border-radius-base);
}

.title {
  margin-bottom: 16px;
}

.subtitle {
}
