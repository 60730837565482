@use '../../../assets/css/deps' as *;

.grid {
  margin-top: 40px;
  max-width: 600px;
}

.error {
  margin-left: calc(50% + 10px);
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  flex-grow: 1;
}
