@use 'sass:math';

@use '../../assets/css/deps' as *;

$rays: 8;
$tick: 0.1s;
$animation-length: $tick * $rays;

@keyframes preloader {
  0% {
    stroke-opacity: 1;
  }
  100% {
    stroke-opacity: 0;
  }
}

.box {
  position: relative;
  display: inline-flex;
  padding: 10px;
}

.loader {
  //@include rmd-theme(stroke, primary);
  stroke-linecap: round;
}

.ray {
  animation: preloader $animation-length linear infinite;
  transform-origin: center;

  @for $i from 1 through $rays {
    &:nth-child(#{$i}) {
      animation-delay: $i * $tick - $animation-length;
      transform: rotate(math.div(($i - 1) * 360deg, $rays));
    }
  }
}

// preloader box
.wrapper {
  position: relative;
}

.inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(var(--color-background-primary-rgb), 0.5);
  border-radius: var(--shape-border-radius-base);
}

.fade {
  z-index: 999;
  //background: linear-gradient(
  //  to right,
  //  var(--color-background-secondary) 0,
  //  rgba(var(--color-background-primary-rgb), 0.5) 50%,
  //  var(--color-background-secondary) 100%
  //);
  background: radial-gradient(rgba(var(--color-background-primary-rgb), 0.1), var(--color-background-inverse));
}
