@use 'deps' as *;

//=======================
// Dynamic variables
//=======================

// Variables can be overwritten in theme config src/components/theme/helpers.ts
// if you want to edit variables in the theme config you must specify them here

// naming from article https://medium.com/eightshapes-llc/naming-tokens-in-design-systems-9e86c7444676
// naming from article https://medium.com/eightshapes-llc/reimagining-a-token-taxonomy-462d35b2b033
// about rgb variable https://stackoverflow.com/a/41265350
// more info in thread https://digifabster.slack.com/archives/C1ENJ3LCW/p1705077129753439

:root {
  // surface
  --color-background-primary: #{$white}; // white
  --color-background-primary-rgb: #{hex_to_rgb($white)};
  --color-background-secondary: #{$neutral-subtlest}; // in figma Surface/Secondary
  --color-background-disabled: #{$neutral-subtlest}; // Surface/Secondary
  --color-background-inverse: #{$neutral-subtle}; // Surface/Inverse

  // border
  --color-border-primary: #{$neutral-subtle};
  //--color-border-secondary: #{$neutral-subtle};
  //--border-focus-color: #{$blue};
  --shape-border-radius-base: #{rem(4)};
  //--shape-border-radius-panel: #{rem(8)};
  //--shape-border-radius-pill: #{rem(12)};
  //--shape-border-radius-container: #{rem(16)};
  //--shape-border-radius-tile: #{rem(16)};

  // text and icons
  --color-text-primary: #{$dark}; // color-text-primary, in figma Font/Primary
  --color-text-primary-rgb: #{hex_to_rgb($dark)};
  --color-text-secondary: #{$neutral-darkest}; // Font/Secondary
  --color-text-secondary-rgb: #{hex_to_rgb($neutral-darkest)};
  --color-text-disabled: #{$neutral-darkest};
  --color-on-background-inverse-default: #{$dark};
  --color-on-background-inverse-hover: #{$primary-color};

  // brand and services UI colors
  --color-ui-primary: #{$primary-color};
  --color-ui-primary-subtle: #{$blue-subtle};
  --color-ui-primary-subtlest: #{$blue-subtlest};
  --color-ui-primary-accent: #{$blue-dark};
  --color-ui-primary-rgb: #{hex_to_rgb($primary-color)};
  --color-on-ui-primary: #{$white};

  --color-ui-secondary: #{$secondary-color};
  --color-ui-secondary-subtle: #{$secondary-color-hover};
  --color-ui-secondary-accent: #{$secondary-color-active};
  --color-ui-secondary-rgb: #{hex_to_rgb($secondary-color)};
  --color-on-ui-secondary: #{$white};

  // todo fill with blue or dark-blue, ask designer for details
  --color-ui-info: #{$info-color};
  --color-ui-info-subtle: #{$info-color-hover};
  --color-ui-info-accent: #{$info-color-active};
  --color-ui-info-rgb: #{hex_to_rgb($info-color)};
  --color-on-ui-info: #{$white};

  --color-ui-warning: #{$warning-color};
  --color-ui-warning-subtle: #{$warning-color-hover};
  --color-ui-warning-accent: #{$warning-color-active};
  --color-ui-warning-rgb: #{hex_to_rgb($warning-color)};
  --color-on-ui-warning: #{$white};

  --color-ui-error: #{$error-color};
  --color-ui-error-subtle: #{$error-color-hover};
  --color-ui-error-accent: #{$error-color-active};
  --color-ui-error-rgb: #{hex_to_rgb($error-color)};
  --color-on-ui-error: #{$white};

  --color-ui-success: #{$success-color};
  --color-ui-success-subtle: #{$success-color-hover};
  --color-ui-success-accent: #{$success-color-active};
  --color-ui-success-rgb: #{hex_to_rgb($success-color)};
  --color-on-ui-success: #{$white};

  // fonts
  //font-line-height-default (120%)
  //font-letter-spacing-default (0)
  --font-size-html: 100%;
  --font-size-heading-1: #{$font-size-heading-1};
  --font-size-heading-2: #{$font-size-heading-2};
  --font-size-heading-3: #{$font-size-heading-3};
  --font-size-heading-4: #{$font-size-heading-4};
  --font-size-heading-5: #{$font-size-heading-5};
  --font-size-heading-6: #{$font-size-heading-6};
  --font-size-body-lg: #{$font-size-body-lg};
  --font-size-body-md: #{$font-size-body-md};
  --font-size-body-sm: #{$font-size-body-sm};
  --font-size-body-xs: #{$font-size-body-xs};

  // other
  --app-background: none; // custom app bg, it's like a component level variable
  --shadow-ui-secondary: #{0 15px 25px rgba(var(--color-ui-secondary-rgb), 0.15)};

  // forms, input
  --input-font-size: #{$font-size-body-md};
  //--input-addition-font-size: #{$font-size-body-xs};

  // button
  --button-1-font-size: #{$button-1-font-size};
  --button-2-font-size: #{$button-2-font-size};

  // chip todo
  --chip-filled-color: #{$error-color-active};
  --chip-filled-background-color: #{$error-color-active};
  //--chip-outlined-color: #{$error-color-active};
  //--chip-outlined-background-color: #{$error-color-active};

  // technology
  --technology-preview-size-width: #{$technology-img-size};
  --technology-preview-size-height: #{$technology-img-size};
  --technology-tile-min-width: #{$technology-tile-min-width};
}
