@use '../../../assets/css/deps' as *;

.layout {
  display: flex;
  flex-grow: 1;

  //@include media_interval($media-huge-desktop, 0) {
  //  min-height: 768px;
  //}
}

.box {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: -20px;
}

.tabs:global(.rmd-tabs) {
  margin-top: 20px;
  display: inline-flex;
  width: auto;
}

.tab:global(.rmd-tab) {
  min-width: 100px;
}

.tab:global(.rmd-tab--active) {
  pointer-events: none;
}
