@use '../../../../assets/css/deps' as *;
@use '../page';

.grid {
  grid-area: specification;

  @if page.$debug-page {
    background-color: $debug-color;
  }
}
