@use '../../../assets/css/deps' as *;

.grid {
  grid-gap: $app-padding-mobile;
  grid-template-columns: auto auto auto 1fr auto;

  @include for-tablet-portrait-up() {
    grid-gap: $app-padding;
  }
}

.info {
  margin-top: 4px;
}

.detail {
  grid-template-columns: auto auto auto;
  justify-content: center;
}
