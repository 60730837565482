@use '../../assets/css/deps' as *;

.box {
  @include rmd-button-theme-update-var(text-height, rem(32px));
  @include rmd-button-theme-update-var(text-horizontal-padding, 16px);
  @include rmd-button-theme-update-var(text-vertical-padding, 0px);

  &:global(.rmd-button) {
    border-radius: 100px;
    @include rmd-theme(color, text-secondary-on-background);
  }

  // no logo case
  &:not(:first-child) {
    margin-left: 35px;
  }

  :global(.rmd-icon) {
    transform: rotate(-90deg);
  }
}

.label {
  margin-right: 16px;

  @include media_interval(0, $media-desktop) {
    display: none;
  }
}

.entry {
  margin-left: 5px;
}

.loader {
  padding: 0;
  margin-left: 7px;
}
