@use '../../assets/css/deps' as *;

.box {
  position: relative;
  display: inline-flex;

  &.counter {
    &:after {
      content: attr(data-progress) '%';
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: var(--font-size-heading-5);
      font-weight: 700;
      @include letter-spacing();
    }
  }
}

.circle {
  stroke: var(--color-border-primary);
}

.progress {
  stroke: currentColor;
  stroke-linecap: round;
  transform-origin: center;
  transform: rotate(-90deg);
  transition: stroke-dashoffset 0.35s linear;
}
