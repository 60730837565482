.layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.header {
  width: 100%;
  padding: 10px 40px;
}

.logo {
  height: 50px;
}

.content-container-layout {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-container {
  max-width: 800px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: var(--color-background-primary);
  border-radius: 1rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  color: var(--color-text-primary);

  p {
    font-size: var(--font-size-body-md);
    line-height: 24px;
  }
}

.tiles-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
