@use '../../assets/css/deps' as *;

.box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.uploader {
  flex-shrink: 0;

  div {
    display: flex;
    flex-shrink: 0;
    flex-wrap: nowrap;
  }
}

.files {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -4px;

  & + .uploader {
    margin-top: 16px;
  }
}

.file {
  &:global(.rmd-chip) {
    overflow: hidden;
    max-width: 220px;
    margin: 4px;
  }
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.btn {
  margin: 4px 4px 4px 15px;

  :global(.rmd-icon) {
    transform: rotate(-90deg);
  }

  &.open {
    :global(.rmd-icon) {
      transform: rotate(90deg);
    }
  }
}
