@use '../../assets/css/deps' as *;

$mobile-menu-icon-spacing-with-text: rem(41px);

.box {
  position: relative;
  backface-visibility: hidden; // force layer to prevent repaint
  z-index: 2;
}

.inner {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

// logo
.logoBox {
  display: flex;
  align-items: center;
  margin-right: 60px;
}

.logo {
  display: inline-flex;
  align-items: center;

  & > img {
    object-fit: contain;
    object-position: center center;
    max-height: 50px;
  }
}

// nav
.nav {
  flex: 1 1;
}

.navList {
  display: flex;
  margin: 0;
  padding: 0;
}

.navItem {
  :global(.rmd-select__value) {
    color: var(--color-on-background-inverse-default);
  }

  :global(.rmd-select) {
    border: none;

    @include rmd-utils-keyboard-exclude(true) {
      &:after {
        content: initial;
      }
    }

    @include rmd-form-theme-update-var(text-height, rem(32));
    @include rmd-form-theme-update-var(label-left-offset, rem(8));
    @include rmd-form-theme-update-var(text-padding-left, rem(29));
    @include rmd-form-theme-update-var(text-padding-right, rem(29));

    &:hover:not(:global(.rmd-select--disabled)) {
      cursor: pointer;
    }

    &:hover {
      :global(.rmd-select__value) {
        color: var(--color-on-background-inverse-hover);
      }
    }
  }

  :global(.rmd-text-field-container--outline-active) {
    :global(.rmd-select__value) {
      @include rmd-theme(color, primary);
    }
  }

  :global(.rmd-text-field-addon:last-child) {
    right: rem(8);
  }

  :global(.rmd-icon) {
    color: var(--color-on-background-inverse-default);
  }
}

.navLink {
  &:global(.rmd-button--text) {
    font-size: var(--button-1-font-size);
    font-weight: 600;
    @include letter-spacing;
    @include rmd-button-theme-update-var(text-horizontal-padding, 8px);
    @include rmd-button-theme-update-var(text-vertical-padding, 8px);
    @include rmd-button-theme-update-var(color, var(--color-on-background-inverse-default));

    &:hover {
      @include rmd-button-theme-update-var(color, var(--color-on-background-inverse-hover));
    }
  }
}

//.navLinkActive {
//  opacity: 0.6;
//  pointer-events: none;
//}

.leftIndents {
  @include for-tablet-landscape-up() {
    margin-left: 30px;
  }
}

.newQuote {
  margin-left: 60px;
}

// language
.language {
  flex: 1 1;
}

.listbox {
  &:global(.rmd-listbox--temporary) {
    margin-top: 0;
    margin-left: 6px;
    border-radius: var(--shape-border-radius-base);
    @include rmd-list-theme-update-var(item-horizontal-padding, 22px);
  }
}

// menu
.menu {
  &:global(.rmd-menu) {
    @include rmd-list-theme-update-var(item-horizontal-padding, 22px);
  }
}

// mobile menu
.mobileMenu {
  &:global(.rmd-menu) {
    @include rmd-list-theme-update-var(vertical-padding, rem(23));
    @include rmd-list-theme-update-var(horizontal-padding, rem(10));
    @include rmd-list-theme-update-var(text-keyline, $mobile-menu-icon-spacing-with-text);
  }
}

.mobileItem {
  &:global(.rmd-list-item) {
    padding-left: $mobile-menu-icon-spacing-with-text;
  }
}

.mobileItemBig {
  &:global(.rmd-list-item) {
    @include rmd-list-theme-update-var(font-size, var(--font-size-heading-5));
  }
}

.closeButton:global(.rmd-button) {
  position: absolute;
  right: 13px;
  top: 13px;
  box-shadow: none;
  @include rmd-button-theme-update-var(icon-size, rem(30px));

  &:before {
    content: initial;
  }
}

// how works
.howItWorksModal {
  max-width: 1000px !important;
  min-width: 300px;
}
