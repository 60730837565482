@use '../../../../../assets/css/deps' as *;

.head {
  .cell {
    padding-top: 0;
    white-space: nowrap;

    &:not(:first-of-type) {
      padding-left: $app-padding-half;
    }
    &:not(:last-of-type) {
      padding-right: $app-padding-half;
    }
  }

  .label {
    margin-bottom: 0;
    //text-transform: capitalize;
  }
}

.row {
  td {
    padding-top: 0;
  }

  &:first-of-type {
    td {
      padding-top: $app-padding;
    }
  }

  //&:last-of-type {
  //  td {
  //    border-bottom: 1px solid var(--color-border-primary);
  //  }
  //}

  td:last-of-type {
    padding-right: $app-padding;
  }
}

.cell {
  //width: 0.1%;
}

.specification {
}
