@use '../../deps' as *;

// overridden classes
.rmd-button--text {
  max-width: 100%;
}

.rmd-button--contained {
  &:before {
    z-index: 1;
  }
}

.rmd-button--outline,
.rmd-button--contained,
.rmd-button--underlined,
.rmd-button--uppercase,
.rmd-button--disabled:not(.rmd-button--flat):not(.rmd-button--outline) {
  font-weight: 800;
  text-transform: uppercase;
  @include letter-spacing('wide');

  // by default these button variants are medium sized, otherwise inherit --button-1-font-size
  // it's a little confusing, but it's easier to set the rmd-button--text-large class than to spam medium class everywhere
  &:not(.rmd-button--text-large) {
    font-weight: 700;
    font-size: var(--button-2-font-size, $button-2-font-size);
    @include rmd-button-theme-update-var(text-height, rem(28px));
  }
}

.rmd-button--disabled {
  @include rmd-button-theme-update-var(outline, inherit);

  // react-md does not set rmd-button--contained for disabled state...
  &:not(.rmd-button--flat):not(.rmd-button--outline) {
    @include rmd-button-theme-update-var(background-color, var(--color-background-disabled));
  }
}

a.rmd-button--disabled {
  pointer-events: none;
}

.rmd-button--primary.rmd-button--contained {
  &:active {
    @include rmd-button-theme-update-var(background-color, var(--color-ui-primary-accent));
  }
}

.rmd-button--secondary.rmd-button--contained {
  &:active {
    @include rmd-button-theme-update-var(background-color, var(--color-ui-secondary-accent));
  }
}

.rmd-button--text-primary.rmd-button--flat {
  &:hover {
    @include rmd-button-theme-update-var(color, rgba(var(--color-ui-primary-rgb), 0.8));
  }

  &:active {
    @include rmd-button-theme-update-var(color, var(--color-ui-primary-accent));
  }
}

.rmd-button--text-secondary.rmd-button--flat {
  &:hover {
    @include rmd-button-theme-update-var(color, rgba(var(--color-ui-secondary-rgb), 0.8));
  }

  &:active {
    @include rmd-button-theme-update-var(color, var(--color-ui-secondary-accent));
  }
}

.rmd-button--outline-primary {
  &:hover {
    box-shadow: var(--color-ui-primary-subtle) 0 0 0 1px inset;
    //@include rmd-button-theme-update-var(color, var(--color-ui-primary-subtle));
    @include rmd-states-theme-update-var(background-color, rgba(var(--color-ui-primary-rgb), 0.1));
  }

  .rmd-circular-progress {
    --rmd-progress-color: var(--color-ui-primary);
  }
}

.rmd-button--outline-secondary {
  &:hover {
    box-shadow: var(--color-ui-secondary-subtle) 0 0 0 1px inset;
    //@include rmd-button-theme-update-var(color, var(--color-ui-secondary-subtle));
    @include rmd-states-theme-update-var(background-color, rgba(var(--color-ui-secondary-rgb), 0.1));
  }

  .rmd-circular-progress {
    --rmd-progress-color: var(--color-ui-secondary);
  }
}

.rmd-button--text.rmd-button--text-error {
  &:hover {
    @include rmd-button-theme-update-var(color, var(--color-ui-error-subtle));
  }

  &:active {
    @include rmd-button-theme-update-var(color, var(--color-ui-error-accent));
  }
}

.rmd-button--icon {
  &:before {
    top: $rmd-button-outline-width;
    left: $rmd-button-outline-width;
    right: $rmd-button-outline-width;
    bottom: $rmd-button-outline-width;
  }

  &:hover {
    @include rmd-states-theme-update-var(background-color, var(--color-background-secondary));
  }

  .rmd-icon {
    position: relative;
  }
}

.rmd-button--icon.rmd-button--outline {
  @include rmd-button-theme-update-var(color, var(--color-text-primary));
}

.rmd-button--icon.rmd-button--text-primary {
  &:hover {
    @include rmd-states-theme-update-var(background-color, var(--color-ui-primary-subtlest));
  }

  &:active {
    @include rmd-states-theme-update-var(background-color, var(--color-ui-primary-subtle));
  }
}

.rmd-button--icon.rmd-button--text-error {
  &:hover {
    @include rmd-states-theme-update-var(background-color, rgba(var(--color-ui-error-rgb), 0.08));
  }

  &:active {
    @include rmd-states-theme-update-var(background-color, rgba(var(--color-ui-error-rgb), 0.16));
  }
}

// custom classes
.rmd-button--neutral.rmd-button--contained {
  // neutral gray button https://www.figma.com/file/6aEXGLD5UsnVqVcL1ROYzM/W_PROD?type=design&node-id=3257-6292&mode=design&t=JcWqxFx3sinASiwS-4
  @include rmd-button-theme-update-var(background-color, var(--color-background-secondary));
  @include rmd-button-theme-update-var(color, var(--color-ui-secondary));
}

.rmd-button--flat {
  @include rmd-button-theme-update-var(text-horizontal-padding, 0px);
  @include rmd-button-theme-update-var(text-vertical-padding, 0px);
  @include rmd-button-theme-update-var(text-height, auto);
  @include rmd-button-theme-update-var(text-min-width, auto);

  @include rmd-utils-keyboard-exclude() {
    &:before {
      content: initial;
    }
  }
}

.rmd-button--underlined {
  text-decoration: underline dashed;
  text-underline-offset: 3px;
}

.rmd-button--inline {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}

.rmd-button--icon-flat {
  &:before {
    content: initial;
  }
}

.rmd-button--icon-circle {
  border-radius: 50%;
}
