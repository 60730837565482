@use '../../../../assets/css/deps' as *;

.box {
  display: inline-flex;
  flex-direction: column;
  align-self: center;
  word-break: break-all;
  min-width: 60px;
}

.label {
  display: inline-flex;
  align-items: center;
}
