@use '../../deps' as *;

.rmd-tabs {
  border: 1px solid var(--color-border-primary);
  border-radius: var(--shape-border-radius-base);

  &:after {
    content: initial;
  }
}

.rmd-tab {
  font-weight: 600;
  font-size: var(--font-size-heading-5);
  @include letter-spacing;
  border-right: 1px solid var(--color-border-primary);

  &:last-of-type {
    border-right: none;
  }

  &:hover:not(:disabled) {
    @include rmd-states-theme-update-var(background-color, var(--color-background-secondary));
  }

  &:disabled {
    opacity: 0.7;
  }

  &--active {
    @include rmd-states-theme-update-var(background-color, var(--color-background-secondary));
  }
}

.rmd-tab__content {
  position: relative;
}
