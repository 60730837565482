.sorting-button {
  padding: 0 10px;
  font-weight: 400;
  color: var(--color-text-secondary);
}

.ascending {
  transform: rotate(180deg);
}

.active {
  color: var(--color-text-primary);
}
