@use '../../../../assets/css/deps' as *;
@use '../page';

.head {
  th {
    padding: 16px 8px 0 8px;
    border-bottom: 1px solid var(--color-border-primary);

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}

.body {
  tr:first-child td {
    padding-top: 12px;
  }

  tr td:first-child {
    padding-left: 6px;
  }
}

.cell {
  padding: 0 8px;
  text-align: center;
  white-space: nowrap;
  width: 1%;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  &:first-child {
    text-align: left;
  }

  &:last-child {
    text-align: right;
  }
}

.dropdown {
  &:global(.rmd-select) {
    @include rmd-form-theme-update-var(text-padding-left, 29px);
    @include rmd-form-theme-update-var(text-padding-right, 29px);
  }

  :global(.rmd-select__value) {
    font-size: var(--font-size-body-sm);
  }

  :global(.rmd-text-field-addon:last-child) {
    right: rem(8);
  }
}

.listbox {
  &:global(.rmd-listbox--temporary) {
    margin-left: 6px;
    @include rmd-list-theme-update-var(item-horizontal-padding, 22px);
  }
}

.hole-error {
  border-radius: 0.188rem;
  outline: 1px solid var(--color-ui-warning-subtle);
  outline-offset: -1px;
}

.label-error {
  width: 100%;
  height: 17px;
  display: flex;
  align-items: flex-end;
}

.error {
  position: absolute;
  padding: 0 !important;
  font-size: var(--font-size-body-sm);
  color: var(--color-ui-error);
}
