@use '../../../assets/css/deps' as *;

.box {
  display: flex;
  align-items: center;
}

.dropdown {
  margin-right: 40px;

  &:global(.rmd-select) {
    border: none;

    &:after {
      content: initial;
    }

    @include rmd-form-theme-update-var(text-padding-right, 0);

    &:hover:not(:global(.rmd-select--disabled)) {
      cursor: pointer;
    }
  }
}

.listbox {
  &:global(.rmd-listbox--temporary) {
    border-radius: var(--shape-border-radius-base);
  }
}
