// flex-wrap
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}

// flex-wrap
.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

// align-items
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}

// justify-content
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}

// flex
.flex-1 {
  flex: 1 1 0;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-none {
  flex: none;
}

// grow
.grow {
  flex-grow: 1;
}
.grow-0 {
  flex-grow: 0;
}

// shrink
.shrink {
  flex-shrink: 1;
}
.shrink-0 {
  flex-shrink: 0;
}

// order
.order-first {
  order: -9999;
}
.order-last {
  order: 9999;
}
