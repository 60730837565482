/*
  Пример:
    input {
      color: #808080;
      ...
      @include placeholder {
        color: red;
      }
    }
*/
@mixin placeholder() {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
