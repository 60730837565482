@use '../../assets/css/deps' as *;

.box {
  display: inline-flex;
  align-items: flex-start;
  @include rmd-icon-theme-update-var(text-spacing, $invoice-icon-spacing-with-text);
}

.icon {
  height: var(--font-size-heading-4);
}
