@use '../../assets/css/deps' as *;

$record-list-gap: var(--record-list-gap, #{rem(12)});

.box {
  padding-bottom: $record-list-gap;

  &:last-of-type {
    padding-bottom: 0;
  }
}

.divider {
  &:global(.rmd-divider) {
    margin-bottom: $record-list-gap;
  }
}

.record {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: $record-list-gap;

  &:last-of-type {
    padding-bottom: 0;
  }
}

.label {
  flex-shrink: 1;
}

.value {
  margin-left: 20px;
  flex-shrink: 0;

  &:empty {
    &:before {
      content: '';
      display: inline-block;
      width: 8px;
      border-bottom: 2px solid rmd-theme-var(error);
      border-radius: 1.5px;
      vertical-align: middle;
    }
  }
}
