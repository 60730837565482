@use 'sass:math';

@use '../../../../assets/css/deps' as *;

$dot-gap: 3px;
$dot-gap-percent: math.div(floor(100px - math.div(100px, $dot-gap)), 1px) * 1%;
$dot-color: var(--color-text-primary);

$item-max-width: 350px;

.box {
}

.grid {
  &:global(.emulated-flex-gap) {
    --gap: #{$app-padding};

    @include for-phone-only() {
      --gap: #{$app-padding-mobile};
    }
  }
}

.max {
  max-width: $item-max-width;
}

.desc {
  width: 100%;
}

.price {
  max-width: 180px;
}

.list {
  padding: 0;
  margin-bottom: 0;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 10px;

  &:before {
    content: '';
    display: inline-block;
    margin-left: $app-padding-half;
    margin-right: $app-padding-half;
    height: 1px;
    flex: 1 1;
    order: 1;
    background: linear-gradient(
        to right,
        transparent 0%,
        transparent $dot-gap-percent,
        $dot-color $dot-gap-percent,
        $dot-color 100%
      )
      $dot-gap bottom repeat-x;
    background-size: $dot-gap 1px;
  }
}

.value {
  order: 2;
}

.delete:global(.rmd-button) {
  order: 3;
  @include rmd-button-theme-update-var(icon-size, rem(30px));
}
