@use '../../../../assets/css/deps' as *;

.box {
  grid-area: products;
}

.table {
}

.head {
  .cell {
    white-space: nowrap;
  }
  .label {
    margin-bottom: 0;
  }
}

.body {
  //td {
  //  @include rmd-theme(background-color, surface);
  //}
}

.firstRow {
  td {
    padding-bottom: $app-padding-mobile;
  }
}

.secondRow {
  vertical-align: baseline;

  td {
    padding-top: 0;
  }

  &.hasDrawings {
    td {
      padding-bottom: $app-padding-mobile;
    }
  }
}

.optionalRow {
  td {
    padding-top: 0;
  }
}

.lastRow {
  td {
    border-bottom: 1px solid var(--color-border-primary);
  }

  &:last-of-type {
    td {
      border-bottom: none;
    }
  }

  // change to .row + .firstRow {border-top: 1px solid var(--color-border-primary);}
}

.errorRow {
  td:first-child {
    background-size: 1px 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(to right, rmd-theme-var(error), rmd-theme-var(error));
  }
}

.cell {
  width: 1%;
}

.field {
  word-break: break-word;
}

.delete {
  width: 81px;
  text-align: right;
}

.material {
  white-space: nowrap;
  width: 1%;
}

.specification {
  //width: 99%;
}

.required:global(.rmd-typography) {
  margin-bottom: $app-padding-mobile;
}

.custom {
  width: calc(100% - 80px);
}

.message {
  display: flex;
  align-items: center;
}

.message-icon {
  width: 18px;
  height: 18px;
}
