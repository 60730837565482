@use '../../../../assets/css/deps' as *;
@use '../page';

.box {
  &:global(.emulated-flex-gap) {
    --h-gap: #{10px};
    --v-gap: #{$app-padding};

    @include for-phone-only() {
      --v-gap: #{$app-padding-mobile};
    }
  }
}

.note {
  margin-top: 6px;
  line-height: 16px;
}
