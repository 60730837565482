@use '../../../../assets/css/deps' as *;
@use '../page';

.box {
  --sticky-bottom: 0;

  border-radius: var(--shape-border-radius-base);
  background-color: var(--color-background-secondary);

  &:global(.sticky-bottom):after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 100%;
    z-index: 1;
    width: 100%;
    height: $app-padding * 1.5;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--color-background-primary));
  }
}

.link {
  border-radius: 0;
  @include rmd-button-theme-update-var(text-min-width, 100%);
  @include rmd-icon-theme-update-var(text-spacing, rem(10px));

  :global(.rmd-icon) {
    transform: rotate(-90deg);
  }
}
