@use '../../../../assets/css/deps' as *;

.grid {
  grid-template-columns: repeat(2, 1fr);

  @include for-tablet-landscape-up() {
    grid-template-columns: repeat(4, 1fr);
  }
}

.grid2 {
  grid-template-columns: repeat(3, 1fr);

  @include for-phone-only() {
    margin-top: $app-padding;
  }
}

.text {
  padding-bottom: 20px;
}

.billing {
  display: grid;
}
