@use '../../../../assets/css/deps' as *;

$large-size: 100px;
$medium-size: 60px;
$small-size: 40px;

.box {
  overflow: hidden;
  position: relative;
  display: inline-flex;
  border: 1px solid var(--color-border-primary);

  &.placeholder {
    align-items: center;
    justify-content: center;

    &.large .icon {
      width: 52px;
      height: 52px;
    }

    &.medium .icon {
      width: 32px;
      height: 32px;
    }

    &.small .icon {
      width: 22px;
      height: 22px;
    }

    .icon {
      position: absolute;
      color: var(--color-border-primary);
    }
  }

  &.large {
    width: $large-size;
    height: $large-size;
  }

  &.medium {
    width: $medium-size;
    height: $medium-size;
  }

  &.small {
    width: $small-size;
    height: $small-size;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    //height: 100%;
    object-fit: contain;
  }
}
