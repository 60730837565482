@use '../../../../assets/css/deps' as *;
@use '../page';

.box {
  grid-area: line-items;
}

.grid {
  @include media_interval(0, page.$media-line-items-column-stack) {
    display: grid;
    grid-gap: page.$line-items-column-gap;
  }

  @include media_interval(page.$media-line-items-stack, page.$media-line-items-column-stack) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media_interval(page.$media-line-items-column-stack, 0) {
    top: 0;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-border-primary);
    border-radius: var(--shape-border-radius-base);
    //height: calc(100vh - #{$app-padding});
    max-height: calc(100vh - #{$app-padding});
    height: 100%;

    @if page.$debug-page {
      background-color: $debug-color;
    } @else {
      @include rmd-theme(background-color, surface);
    }
  }
}

.half {
  @include media_interval(page.$media-line-items-scrollable, page.$media-line-items-column-stack) {
    max-height: 470px;
  }

  @include media_interval(0, page.$media-line-items-column-stack) {
    border: 1px solid var(--color-border-primary);
    border-radius: var(--shape-border-radius-base);
  }

  @include media_interval(page.$media-line-items-column-stack, 0) {
    // one open panel fills the entire column
    &:global(.rmd-expansion-panel--expanded) {
      flex: 1;
      overflow: hidden;
    }

    // when both panels are open they are equal
    .bothOpen & {
      max-height: 50%;
    }
  }
}

.counter {
  padding: rem(2) rem(4);
  min-width: rem(20);
  border-radius: var(--shape-border-radius-base);
}

.models {
  .body {
    @include media_interval(page.$media-line-items-column-stack, 0) {
      border-bottom: 1px solid var(--color-border-primary);
    }
  }

  &:global(.rmd-expansion-panel--expanded) + :not(:global(.rmd-expansion-panel--expanded)) .head {
    border-bottom: none;
  }
}

.cart {
}

.head {
  padding: $app-padding-half $app-padding-mobile;
  border-bottom: 1px solid var(--color-border-primary);
  @include rmd-icon-theme-update-var(text-spacing, rem(10));

  @include media_interval(0, page.$media-line-items-column-stack) {
    border-bottom: none;
  }
}

.body {
  padding: page.$line-items-column-gap;
  z-index: 0;

  @include media_interval(page.$media-line-items-scrollable, 0) {
    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100px;
      pointer-events: none;
    }

    &:before {
      top: 0;
      background: linear-gradient(180deg, var(--color-background-primary) $app-padding, rgba(255, 255, 255, 0) 100%);
      //background: linear-gradient(180deg, var(--color-background-secondary) 0%, rgba(244, 246, 249, 0) 100%);
      opacity: var(--p-column-top-overlay-opacity, 0);
    }

    &:after {
      bottom: 0;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--color-background-primary));
      opacity: var(--p-column-bottom-overlay-opacity, 0);
    }
  }
}

.scrollable {
  @include media_interval(page.$media-line-items-scrollable, 0) {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    @include rmd-utils-hide-scrollbar;
  }
}

.list {
  margin: 0;
  padding: 0;

  @if page.$debug-page {
    @include media_interval(page.$media-line-items-scrollable, 0) {
      height: 600px;
      background-color: $debug-color;
    }
  }
}

.empty {
  @include media_interval(page.$media-line-items-scrollable, 0) {
    &:global(.margin-top) {
      margin-top: 50px;
    }
  }
}

.error {
  padding: 10px 20px 0;
  font-weight: 600;
  text-align: center;
  color: var(--color-ui-error);
}
