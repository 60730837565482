@use 'sass:math';
@use 'deps' as *;

// app compound styles

// main layout
#page {
  display: flex;
  flex-direction: column;
  position: relative;
}

#main {
  position: relative;
  z-index: 2; // fix for ios chrome
  flex-grow: 1;
}

.container {
  width: 100%;
  max-width: $grid-max-width;
  margin-right: auto;
  margin-left: auto;
  padding-left: $grid-gutter-width-mobile;
  padding-right: $grid-gutter-width-mobile;

  @include for-tablet-landscape-up() {
    padding-left: $grid-gutter-width-landscape;
    padding-right: $grid-gutter-width-landscape;
  }

  @include for-big-desktop-up() {
    padding-left: $grid-gutter-width-wide;
    padding-right: $grid-gutter-width-wide;
  }

  .no-gutters-mobile {
    @include media_interval(0, 380px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// boxes
.rounded-box {
  overflow: hidden;
  @include rmd-theme(background-color, surface);
  border-radius: var(--shape-border-radius-base);
}

// sticky
.sticky-top {
  --sticky-top: #{$app-padding};
  position: sticky;
  top: var(--sticky-top);
}

.sticky-bottom {
  --sticky-bottom: #{$app-padding};
  position: sticky;
  bottom: var(--sticky-bottom);
}

// type
.text-overflow-ellipsis {
  @include rmd-typography-text-overflow-ellipsis;
}

.input-addition-font-size {
  font-size: var(--font-size-body-xs);
  //font-size: var(--input-addition-font-size);
}

// form
.fieldset-grid {
  --column-gap: #{$app-padding};
  --row-gap: #{$app-padding};

  display: grid;
  column-gap: var(--column-gap);
  row-gap: var(--row-gap);
  width: 100%;

  @include for-phone-only() {
    column-gap: $app-padding-mobile;
    row-gap: $app-padding-mobile;
  }

  &--columns {
    --columns: 2;
    grid-template-columns: repeat(var(--columns), 1fr);
  }
}

.fieldset-grid-cell {
  &--full {
    grid-column: span var(--columns);
  }
}

// table
// todo move to app base table component (.table {})
table {
  thead {
    td {
      padding: $app-padding $app-padding-mobile 0 $app-padding-mobile;

      &:first-of-type {
        padding-left: $app-padding;
      }

      &:last-of-type {
        padding-right: $app-padding;
      }
    }
  }

  tbody {
    td {
      padding: $app-padding $app-padding-half;

      &:first-of-type {
        padding-left: $app-padding;
      }

      &:last-of-type {
        padding-right: 8px;
      }
    }
  }
}
