@use '../../../assets/css/deps' as *;

.layout {
}

.box {
}

.btn {
  margin-right: $app-padding-mobile;
}

.table {
  min-height: 120px;
  @include media_interval(0, $media-tablet-landscape) {
    min-width: 718px;
  }
}

.grid {
  display: grid;
  grid-template-columns: 6% 2fr 2fr 3fr 1fr;
  grid-gap: 1%;

  @include media_interval(0, $media-desktop) {
    grid-template-columns: 6% 2fr 1fr 1fr 1fr;
  }
}

.head {
  padding: 0 $app-padding;
  margin-bottom: 25px;

  > .title {
    margin-bottom: 0;
  }
}

.row {
  padding: 5px $app-padding;
  border-radius: var(--shape-border-radius-base);

  &:last-of-type {
    margin-bottom: 15px;
  }

  &:hover {
    background-color: var(--color-ui-primary-subtlest);
  }
}

.cell {
  display: flex;
  align-items: center;
  word-break: break-word;
}

.right {
  justify-content: flex-end;
}

.radio:hover {
  color: var(--color-ui-primary-accent);
}

.checked {
  color: var(--color-ui-primary);
}

.checked:hover {
  color: var(--color-ui-primary);
}

.input {
  display: none;
}

.label {
  margin: 0;
  font-size: var(--font-size-body-sm);
  color: inherit;
}

.button {
  padding-top: 6px;
  padding-bottom: 6px;
}

.buttonLoading {
  padding-left: 8.5px;
  padding-right: 8.5px;
}

.buttonContainer {
  min-height: 18px;
  align-items: center;
}
