@use '../../assets/css/deps' as *;

$color-indicator-offset: rem(5);
$color-indicator-size: rem(16);

.select {
}

// todo fetch to component, we have implemented this ball 9999 times
//  check backoffice example
.indicator {
  display: inline-block;
  width: $color-indicator-size;
  height: $color-indicator-size;
  margin-right: $color-indicator-offset;
  border-radius: 50%;
  border: 1px solid var(--color-border-primary);
  background-clip: content-box;
}

.label {
}
