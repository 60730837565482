@use '../../../../assets/css/deps' as *;

.header {
  &:global(.rmd-dialog__header) {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 36px;
  }
}

.close {
  &:global(.rmd-button) {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.file {
  flex: 1 1;
  height: 100%;
  margin-left: 20px;
  justify-content: center !important;
}
