@use '../../../../assets/css/deps' as *;

.table {
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.title-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-weight: 700;
}

.status {
  flex-shrink: 1;
  padding: 9px 16px;
  background-color: var(--color-ui-primary-subtlest);
  border-radius: 8px;
}

.head {
  .cell {
    padding-top: 0;
    white-space: nowrap;

    &:not(:first-of-type) {
      padding-left: $app-padding-half;
    }
    &:not(:last-of-type) {
      padding-right: $app-padding-half;
    }
  }
}

.body {
  counter-reset: purchase;
}

.row {
  td {
    padding-top: 0;
  }

  &:first-of-type {
    td {
      padding-top: $app-padding;
      border-top: 1px solid var(--color-border-primary);
    }
  }

  td:last-of-type {
    padding-right: $app-padding;
  }
}

.cell {
  //width: 0.1%;
}

.counter {
  width: 0.1%;
}

.specification {
  max-width: 300px;
  min-width: 200px;
  white-space: initial;
}

.number {
  &:before {
    content: counter(purchase);
    counter-increment: purchase;
  }
}
