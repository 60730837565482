@use '../../deps' as *;

// class for usage with '@components/alert' component as a child
// just overrides rmd-toast base styles
.rmd-toast--alert {
  padding: 0;
  background-color: transparent;
}

.rmd-toast--notification {
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
}
