@use '../../../../assets/css/deps' as *;

.box {
}

.form {
  //align-items: center;
  align-items: flex-start;
  grid-template-columns: repeat(2, 1fr);
  //grid-template-columns: minmax(0, 50%) minmax(0, 50%);
  //grid-template-columns: auto 1fr;
}

.number {
  min-width: 120px;
}

.file {
  &:global(.rmd-chip) {
    @include media_interval($media-invoice-layout-stack, 0) {
      max-width: 120px;
    }
  }
}

.error {
  margin-top: 18px;
}
