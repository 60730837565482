@use '../../../../assets/css/deps' as *;

.box {
  margin-top: 4px;
  margin-right: 20px;

  @include media_interval($media-huge-desktop, 0) {
    max-width: 340px;
  }
}
