@use '../../assets/css/deps' as *;

.box {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.button {
  width: 32px;
  height: 32px;
  border: 1px solid var(--color-ui-primary);
  box-sizing: border-box;
  border-radius: var(--shape-border-radius-base);
  margin-left: 4px;
  margin-right: 4px;
}

.button_link {
  font-size: var(--font-size-body-sm);
  width: 100%;
  height: 100%;
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include rmd-theme(color, primary);
}

.break {
  @include rmd-theme(background-color, background);
  border: 1px solid var(--color-border-primary);
  box-sizing: border-box;
  border-radius: var(--shape-border-radius-base);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.break_link {
  width: auto;
  height: 7px;
  display: flex;
  line-height: 0;
  cursor: pointer;
  @include rmd-theme(color, text-hint-on-background);
}

.active {
  @include rmd-theme(background-color, background);
  border: 1px solid var(--color-border-primary);
}

.active_link {
  @include rmd-theme(color, text-hint-on-background);
}

.wrapped {
  margin-top: 32px;
  > ul {
    justify-content: center;
  }
}
