@use '../../assets/css/deps' as *;

.actions {
  &:global(.emulated-flex-gap) {
    --gap: #{$app-padding};

    @include for-phone-only() {
      --gap: #{$app-padding-mobile};
    }
  }
}
