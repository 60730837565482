@use '../../../../../assets/css/deps' as *;

.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;
  gap: 12px;

  @include for-desktop-up() {
    flex-direction: row;
    gap: unset;
  }
}

.title {
  text-align: center;
  color: var(--color-ui-secondary);

  @include for-desktop-up() {
    text-align: unset;
  }
}

.centering-box {
  width: 100%;

  @include for-desktop-up() {
    width: 35%;
  }
}

.tabs:global(.rmd-tabs) {
  width: auto;
}

.tab:global(.rmd-tab) {
  min-width: 100px;
}

.tab:global(.rmd-tab--active) {
  pointer-events: none;
}
