@use '../../../assets/css/deps' as *;

.layout {
  display: flex;
  flex-grow: 1;
}

.container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.dropzone {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

@media only screen and (min-height: 860px) {
  .container {
    align-items: center;
  }
  .dropzone {
    min-height: 708px;
    flex-grow: 0;
  }
}

// preview
.preview {
  padding: 50px;
  max-width: 735px;
  //max-width: 640px;
  text-align: center;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    display: inline-block;
    width: 100px;
    height: 70px;
    margin-bottom: 30px;
    background: no-repeat url('files.png');
    background-size: cover;
  }
}

.subtitle {
  font-size: var(--font-size-body-md);
  line-height: 1.5;
  @include letter-spacing;
}

.gap {
  margin-top: 30px;
}

// hint
.hint {
  display: inline-flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
}
