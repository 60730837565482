@use '../../../../../assets/css/deps' as *;

$fields_gap: rem(8);

.box {
  height: 100%;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
}

.title {
  margin-bottom: 6px;
}

.line {
  margin-top: 3px;
}

.form {
}

.sizes {
  &:global(.emulated-flex-gap) {
    --gap: #{$fields_gap};
    margin-right: $fields_gap;
  }
}

.size {
}

.label {
  margin-right: rem(5px);
  text-transform: capitalize;
}

.units {
}
