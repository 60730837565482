@use 'sass:math';
@use 'deps' as *;

// custom reset + base styles

//@keyframes appFadeIn {
//  0% {
//    filter: blur(20px);
//  }
//  100% {
//    filter: blur(0);
//  }
//}

html {
  font-size: var(--base-font-size, 100%); // https://www.youtube.com/watch?v=dHbYcAncAgQ
  line-height: 1;
  //animation: appFadeIn 0.4s forwards $animation-easing;
  transition: background-color 0.2s;

  // Remove Scrollbar "Jumping"
  margin-right: calc(100% - 100vw);

  //font-size: calc((16 / 1920) * 100vw); for screens > 1920
}

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-osx-font-smoothing: grayscale;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    background: var(--app-background, none);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ol,
ul {
  list-style: none;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

label {
  display: inline-block;

  &[for] {
    cursor: pointer;
  }
}

textarea {
  resize: none;
}

a {
  text-decoration: none;
}

:focus {
  outline: none;
}

// autofill background color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: none; // firefox
  transition: background-color 9999s ease-in-out 0s; // chrome
}

//input:-webkit-autofill,
//input:-webkit-autofill:hover,
//input:-webkit-autofill:focus {
//  -webkit-box-shadow: 0 0 0 40rem #ffff inset;
//}

// autofill font styles
// input:-internal-autofill-previewed {
//   color: var(--color-text-primary) !important;
// font-family: inherit !important;
// font-weight: inherit !important;
// font-size: var(--input-font-size) !important;
// }

input:-webkit-autofill {
  caret-color: var(--color-text-primary) !important;
}

input:-webkit-autofill::first-line {
  color: var(--color-text-primary) !important;
  // font-family: inherit !important;
  // font-weight: inherit !important;
  // font-size: var(--input-font-size) !important;
}

// table
table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

// other
svg {
  * {
    // make sit so that paths and other things won't be event targets
    // which makes things easier to determine if something is an icon or
    // not
    pointer-events: none;
  }
}
