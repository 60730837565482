@use '../../../assets/css/deps' as *;

.box {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  @include rmd-form-theme(padding-right, text-padding-right);
}

.link:global(.rmd-button--text) {
  @include letter-spacing();
}
