@use '../../assets/css/deps' as *;

.layout {
  padding-top: 90px;

  @include for-tablet-landscape-up() {
    padding-top: 180px;
  }

  @include for-desktop-up() {
    padding-top: 220px;
  }
}

.label {
  @include rmd-theme(color, text-secondary-on-background);
  @include font-size(120px);
  @include media_interval($media-desktop, $media-big-desktop) {
    @include font-size(90px);
  }
  @include media_interval($media-tablet-landscape, $media-desktop) {
    @include font-size(75px);
  }
  @include media_interval($media-tablet-portrait, $media-tablet-landscape) {
    @include font-size(60px);
  }
  @include media_interval(0, $media-tablet-portrait) {
    @include font-size(50px);
  }
}
