@use '../../../../assets/css/deps' as *;

.actions {
}

.action {
  margin: 5px 10px 0;

  &.open :global(.rmd-icon) {
    transform: rotate(180deg);
  }
}

.cell {
  display: flex;
  flex-direction: column;
  //align-items: center;
}

.quantityCell {
  align-items: center;
}

.priorityCell {
  align-items: flex-start;
  flex-grow: 1;

  :global(.rmd-typography--subtitle-2) {
    min-width: 72px;
    text-align: center;
  }
}

.priority {
  width: 100%;
}

.note {
  margin-top: 6px;
  line-height: 16px;
}
