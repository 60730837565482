@use 'sass:map';
@use 'sass:math';
@use '../variables' as *;
@use '../functions' as *;

$-rmd-typography-font-family: var(--font-family-body, ProximaNova, Arial, sans-serif);
$-rmd-chip-height: rem(24px);

/*
  Entrypoint to react-md modules
  https://react-md.dev/guides/customizing-your-theme
  https://sass-lang.com/documentation/at-rules/forward/#configuring-modules
*/
@forward 'react-md' with (
  //=======================
  // Theme
  //=======================
  $rmd-theme-primary: var(--color-ui-primary),
  $rmd-theme-on-primary: var(--color-on-ui-primary),
  $rmd-theme-secondary: var(--color-ui-secondary),
  $rmd-theme-on-secondary: var(--color-on-ui-secondary),
  $rmd-theme-warning: var(--color-ui-warning),
  $rmd-theme-on-warning: var(--color-on-ui-warning),
  $rmd-theme-error: var(--color-ui-error),
  $rmd-theme-on-error: var(--color-on-ui-error),
  $rmd-theme-success: var(--color-ui-success),
  $rmd-theme-on-success: var(--color-on-ui-success),
  $rmd-theme-background: var(--color-background-inverse),
  //$rmd-theme-surface: var(--color-background-primary),
  $rmd-theme-primary-text-on-background-color: var(--color-text-primary),
  $rmd-theme-secondary-text-on-background-color: var(--color-text-secondary),
  $rmd-theme-hint-text-on-background-color: var(--color-text-secondary),
  $rmd-theme-disabled-text-on-background-color: var(--color-text-disabled),
  // todo the variable was set to avoid the error
  $rmd-theme-icon-on-background-color: var(--color-text-primary),
  //=======================
  // Utils
  //=======================
  $rmd-utils-enable-rtl: false,
  $rmd-utils-skip-validation: not $debug,
  $rmd-utils-phone-max-width: 599px,
  $rmd-utils-tablet-min-width: 600px,
  $rmd-utils-tablet-max-width: 1023px,
  $rmd-utils-desktop-min-width: 1024px,
  $rmd-utils-large-desktop-min-width: 1440px,

  //=======================
  // Typography
  //=======================
  $rmd-typography-font-family: $-rmd-typography-font-family,
  $rmd-typography-semi-bold: 600,
  $rmd-typography-headline-1-styles: (
    font-size: var(--font-size-heading-1, $font-size-heading-1),
    line-height: 1,
    font-weight: 700,
    letter-spacing: map.get($letter-spacings, 'default'),
    color: var(--headline-1-color, var(--color-text-primary))
  ),
  $rmd-typography-headline-2-styles: (
    font-size: var(--font-size-heading-2, $font-size-heading-2),
    line-height: 1,
    font-weight: 700,
    letter-spacing: map.get($letter-spacings, 'default'),
    color: var(--headline-2-color, var(--color-text-primary))
  ),
  $rmd-typography-headline-3-styles: (
    font-size: var(--font-size-heading-3, $font-size-heading-3),
    line-height: 1,
    font-weight: 600,
    letter-spacing: map.get($letter-spacings, 'default'),
    color: var(--headline-3-color, var(--color-text-primary))
  ),
  $rmd-typography-headline-4-styles: (
    font-size: var(--font-size-heading-4, $font-size-heading-4),
    line-height: 1,
    font-weight: 600,
    letter-spacing: map.get($letter-spacings, 'default'),
    color: var(--headline-4-color, var(--color-text-primary))
  ),
  $rmd-typography-headline-5-styles: (
    font-size: var(--font-size-heading-5, $font-size-heading-5),
    line-height: 1,
    font-weight: 600,
    letter-spacing: map.get($letter-spacings, 'default'),
    color: var(--headline-5-color, var(--color-text-primary))
  ),
  $rmd-typography-headline-6-styles: (
    font-size: var(--font-size-heading-6, $font-size-heading-6),
    line-height: 1,
    font-weight: 600,
    letter-spacing: map.get($letter-spacings, 'default')
  ),
  $rmd-typography-button-styles: (
    font-size: var(--button-1-font-size, $button-1-font-size),
    line-height: 1,
    font-weight: 600,
    letter-spacing: map.get($letter-spacings, 'tracked'),
    text-decoration: none,
    text-transform: none
  ),
  $rmd-typography-subtitle-1-styles: (
    font-size: var(--font-size-body-lg, $font-size-body-lg),
    line-height: rem(23px),
    font-weight: 400,
    letter-spacing: map.get($letter-spacings, 'default'),
    text-decoration: none,
    text-transform: none
  ),
  $rmd-typography-subtitle-2-styles: (
    font-size: var(--font-size-body-xs, $font-size-body-xs),
    line-height: 1,
    font-weight: 400,
    letter-spacing: map.get($letter-spacings, 'default'),
    text-decoration: none,
    text-transform: none,
    color: var(--color-text-secondary)
  ),
  $rmd-typography-body-1-styles: (
    font-size: var(--font-size-body-md, $font-size-body-md),
    line-height: 1.5,
    font-weight: 400,
    letter-spacing: map.get($letter-spacings, 'default'),
    text-decoration: none,
    text-transform: none
  ),
  $rmd-typography-body-2-styles: (
    font-size: var(--font-size-body-sm, $font-size-body-sm),
    line-height: 1.5,
    font-weight: 400,
    letter-spacing: map.get($letter-spacings, 'default'),
    text-decoration: none,
    text-transform: none
  ),
  // replaced with src/assets/css/utilities/_font_weight.scss to allow overriding buttons font-weight
  $rmd-typography-default-font-weights: (),
  $rmd-typography-font-styles: normal italic,
  $rmd-typography-colors: (
    secondary: text-secondary-on-background,
    hint: text-hint-on-background,
    theme-primary: primary,
    theme-secondary: secondary,
    theme-success: success,
    // absent in Typography.color component's attribute, but was generated in css
    primary: text-primary-on-background,
    theme-warning: warning,
    theme-error: error
  ),
  $rmd-typography-decorations: (),
  $rmd-typography-transforms: (),
  //=======================
  // States
  //=======================
  $rmd-states-focus-shadow-color: var(--color-ui-primary),
  //=======================
  // Icon
  //=======================
  $rmd-icon-size: 1rem,
  $rmd-icon-spacing-with-text: rem(5px),
  $rmd-icon-include-dense: false,
  $rmd-icon-use-font-icons: false,

  //=======================
  // App-bar
  //=======================
  $rmd-app-bar-default-background-color: var(--color-background-disabled),
  $rmd-app-bar-default-color: var(--color-text-primary),
  //=======================
  // Button
  //=======================
  $rmd-button-text-vertical-padding: rem(10px),
  $rmd-button-text-horizontal-padding: rem(20px),
  $rmd-button-text-border-radius: var(--shape-border-radius-base),
  $rmd-button-text-height: rem(40px),
  $rmd-button-color: var(--color-text-secondary),
  $rmd-button-outline-color: var(--color-border-primary),
  $rmd-button-outline-width: 1px,
  $rmd-button-contained-resting-elevation: 0,
  $rmd-button-contained-pressed-elevation: 0,
  $rmd-button-icon-size: rem(40px),
  $rmd-button-icon-border-radius: var(--shape-border-radius-base),
  //=======================
  // Card
  //=======================
  $rmd-card-elevation: 0,
  $rmd-card-border-radius: 0,
  $rmd-card-header-padding: $app-padding,
  $rmd-card-content-padding: $app-padding,
  $rmd-card-secondary-color: var(--color-text-secondary),
  //=======================
  // Form
  //=======================
  $rmd-label-font-size: rem(15),
  $rmd-text-field-height: rem(40),
  $rmd-text-field-outline-padding: rem($app-padding),
  $rmd-text-field-active-color: var(--color-ui-primary),
  $rmd-text-field-border-color: var(--color-border-primary),
  $rmd-text-field-border-hover-color: var(--color-ui-primary),
  $rmd-text-field-border-radius: var(--shape-border-radius-base),
  $rmd-text-field-border-width: 1px,
  $rmd-text-field-border-width-active: 1px,
  $rmd-text-field-addon-margin: rem(8px),
  $rmd-textarea-vertical-padding: rem(13px),
  $rmd-form-error-hover-color: var(--color-ui-error-accent),
  $rmd-switch-track-height: rem(20px),
  $rmd-switch-track-width: rem(36px),
  $rmd-switch-track-background-color: var(--color-background-primary),
  $rmd-switch-track-border-radius: rem(10px),
  $rmd-switch-ball-size: rem(16px),
  $rmd-switch-ball-offset: rem(1px),
  $rmd-switch-ball-disabled-color: var(--color-text-disabled),
  //=======================
  // Divider
  //=======================
  $rmd-divider-spacing: 0,
  $rmd-divider-background-color: var(--color-border-primary),
  //=======================
  // Avatar
  //=======================
  // todo the variable was set to avoid the error
  $rmd-avatar-border-color: black,

  //=======================
  // Progress
  //=======================
  $rmd-progress-include-linear: false,
  // TODO for linear
  $rmd-progress-background-color: $white,
  $rmd-progress-color: var(--color-background-secondary),
  $rmd-circular-progress-size: 2rem,
  $rmd-circular-progress-small-size: 1rem,
  $rmd-circular-progress-stroke-width: 10,

  //=======================
  // List
  //=======================
  $rmd-list-vertical-padding: rem(5px),
  $rmd-list-item-vertical-padding: rem(7px),
  $rmd-list-item-horizontal-padding: rem(20px),
  $rmd-list-item-height: auto,
  $rmd-list-item-medium-height: auto,
  $rmd-list-line-height: 1,
  $rmd-list-font-size: var(--input-font-size),
  $rmd-option-selected-content: false,
  $rmd-option-selected-styles: (font-weight: 600),
  $rmd-option-focused-styles: (background-color: var(--color-background-secondary)),
  //=======================
  // Menu
  //=======================
  //$rmd-menu-color: var(--color-text-primary), // TODO it's equal to on-surface
  //=======================
  // Alert
  //=======================
  $rmd-toast-border-radius: var(--shape-border-radius-base),
  //$rmd-toast-background-color: var(--color-text-primary),
  //$rmd-toast-color: var(--color-background-primary),
  $rmd-toast-background-color: var(--color-background-primary),
  $rmd-toast-color: var(--color-text-primary),
  //=======================
  // Link
  //=======================
  $rmd-link-transition-time: 0.1s,
  $rmd-link-color: var(--color-ui-primary),
  $rmd-link-hover-color: rgba(var(--color-ui-primary-rgb), 0.8),
  $rmd-link-visited-color: var(--color-ui-primary-accent),
  //=======================
  // Transition
  //=======================
  $rmd-transition-scale-y-enter-duration: 0.05s,
  $rmd-transition-scale-y-leave-duration: 0.1s,
  $rmd-cross-fade-translate-distance: 0,
  //$rmd-cross-fade-translate-distance: rem(10px),
  //=======================
  // Overlay
  //=======================
  $rmd-overlay-transition-duration: 0.4s,
  //$rmd-overlay-color: rgba($black, 0.2),
  //=======================
  // Dialog
  //=======================
  $rmd-dialog-min-width: rem(400px),
  $rmd-dialog-horizontal-margin: 0,
  $rmd-dialog-vertical-margin: 0,
  $rmd-dialog-header-padding: $app-padding,
  $rmd-dialog-header-padding-bottom: 0,
  $rmd-dialog-content-padding: $app-padding,
  $rmd-dialog-footer-padding: $app-padding,

  //=======================
  // Tabs
  //=======================
  $rmd-tab-height: rem(40px),
  $rmd-tab-vertical-padding: rem(10px),
  $rmd-tab-horizontal-padding: rem(20px),
  $rmd-tab-min-width: auto,
  $rmd-tab-inactive-color: var(--color-ui-secondary),
  $rmd-tab-active-color: var(--color-ui-secondary),
  //=======================
  // Chip
  //=======================
  $rmd-chip-height: $-rmd-chip-height,
  $rmd-chip-border-radius: math.div($-rmd-chip-height, 2),
  $rmd-chip-small-spacing: rem(15px),
  $rmd-chip-medium-spacing: rem(15px),
  $rmd-chip-large-spacing: rem(15px),
  $rmd-chip-themed-background-color: var(--color-background-disabled),
  $rmd-chip-themed-color: var(--color-text-primary),
  $rmd-chip-solid-background-color: var(--color-background-disabled),
  $rmd-chip-solid-color: var(--color-text-primary),
  //=======================
  // Tooltip
  //=======================
  $rmd-tooltip-font-size: rem(12px),
  $rmd-tooltip-line-height: math.div(18, 12),
  $rmd-tooltip-horizontal-padding: rem(10px),
  $rmd-tooltip-line-wrap-vertical-padding: rem(10px),
  $rmd-tooltip-background-color: var(--color-text-primary),
  $rmd-tooltip-color: var(--color-background-primary),
  $rmd-tooltip-enter-duration: 0.05s,
  //$rmd-tooltip-transition-distance: 0,
  $rmd-tooltip-border-radius: var(--shape-border-radius-base),
  //=======================
  // Other
  //=======================
  //$rmd-grid-cell-margin-var: --rmd-cell-margin,
);
