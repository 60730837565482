@use '../../../../assets/css/deps' as *;

// https://stackoverflow.com/a/75067528

.row {
  width: 100%;
}

.rowHalf {
  @include for-tablet-portrait-up() {
    flex: 1 1;
    min-width: 45%;
  }
}
