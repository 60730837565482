@use '../../assets/css/deps' as *;

.widget-panel {
  padding: 4px 25px;
  display: flex;
  justify-content: space-between;
  background-color: $green;
  align-items: baseline;
  color: $white;
}

.button-close {
  background-color: $white;
  color: $green;
}
