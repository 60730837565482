@use 'sass:math';
@use '../functions/rem' as *;

@mixin font-size($size, $line_height: 0) {
  @if $size != 0 {
    font-size: rem($size);
  }

  @if $line_height != 0 {
    line-height: math.div($line-height, $size);
  }
}
