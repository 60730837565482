@use '../../../../assets/css/deps' as *;
@use '../page';

.box {
  //contain: content;
  //contain: paint;
  grid-area: calculation;
  border: 1px solid var(--color-border-primary);

  @if page.$debug-page {
    background-color: $debug-color;
  } @else {
    @include rmd-theme(background-color, surface);
  }

  :global .sticky-top {
    --sticky-top: 0;
  }

  // TODO it's cuts off "confirm" button shadow
  //:global(.sticky-inner-wrapper) {
  //  contain: content;
  //}
}

.already {
  &:last-of-type {
    margin-bottom: 0;
  }
}

.actions {
  &:global(.emulated-flex-gap) {
    --gap: #{$app-padding-half};
  }
}
