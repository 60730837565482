@font-face {
  font-family: 'ProximaNova';
  /*src: url('proximanova-bold.woff2') format('woff2'),*/
  /*     url('proximanova-bold.woff') format('woff'),*/
  /*     url('proximanova-bold.ttf') format('truetype');*/

  src: url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3')
      format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
