.overflow-clip {
  overflow: clip;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-initial {
  overflow: initial;
}
.overflow-visible {
  overflow: visible;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
