@use '../../../../../../assets/css/deps' as *;

.row {
  align-items: flex-start;
}

.delete {
  right: -10px;
}

.help {
  vertical-align: middle;
  text-transform: lowercase;
}
