@use '../../assets/css/deps' as *;

.btn {
  //@include rmd-button-theme(color);
  //
  //&:visited,
  //&:hover {
  //  @include rmd-button-theme(color);
  //}
}

.back {
  @include rmd-icon-theme-update-var(text-spacing, rem(10px));

  :global(.rmd-icon) {
    transform: rotate(90deg);
  }
}
