@use '../../../../assets/css/deps' as *;

.box {
  display: inline-grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 5px;
  pointer-events: none;
  position: absolute;
  z-index: 1;

  button {
    pointer-events: auto;
    @include rmd-theme(background-color, surface);
  }
}
