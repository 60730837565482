@use '../../assets/css/deps' as *;

.box {
  padding: 0;
  margin: 0;
}

.item {
  &:not(:first-of-type) {
    padding-top: 10px;
  }

  padding-left: 10px;
  margin-left: 6px;

  &::marker {
    content: '•';
    display: inline-block;
    font-size: 1.125rem;
  }
}
