@use '../../assets/css/deps' as *;

$sidebar-section-gap: var(--sidebar-section-gap, #{$app-padding-mobile});

.box {
  &:global(.base-paddings) {
    padding-bottom: $sidebar-section-gap;
  }
}

.divider {
  &:global(.rmd-divider) {
    margin-bottom: $sidebar-section-gap;
  }
}
