@use '../../../../assets/css/deps' as *;

.box {
}

:global(.rmd-tabs).tabs {
  display: inline-flex;
  width: auto;

  :global(.rmd-tab) {
    min-width: 137px;
  }
}

:global(.rmd-tabs).validationErrOnTabs {
  border: 1px solid rmd-theme-var(error);
  :global(.rmd-tab) {
    border-right: 1px solid rmd-theme-var(error);
    &:last-of-type {
      border-right: none;
    }
  }
}

.validationErrText {
  margin-top: 5px;
}

.rates {
}
