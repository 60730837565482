@use '../../assets/css/deps' as *;

.cell {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.head {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.body {
  position: relative;
  width: 100%;
}

.error {
  &:global(.rmd-typography) {
    margin-top: 8px;
    display: inline-block;
    max-width: 100%;

    &.tooltip {
      @include for-tablet-portrait-up() {
        margin-top: 0;
        display: flex;
        align-items: center;
        padding: 7px 10px;
        box-sizing: border-box;
        max-width: 250px;
        position: absolute;
        top: 50%;
        left: -12px;
        transform: translate(-100%, -50%);
        border-radius: var(--shape-border-radius-base);
        @include rmd-theme(background-color, error);
        @include rmd-theme(color, on-error);

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: calc(100% - 1px);
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 8px solid $red;
        }
      }
    }
  }
}

.nonFieldErrors {
  margin-top: -10px;
}
