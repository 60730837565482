@use '../../deps' as *;

.rmd-divider {
  border-style: solid;
  border-bottom: none;
  margin-bottom: $app-padding;

  @include for-phone-only() {
    margin-bottom: $app-padding-mobile;
  }
}

.rmd-divider-no-gutters {
  margin-left: -$app-padding;
  margin-right: -$app-padding;
  width: calc(100% + #{$app-padding * 2});

  @include for-phone-only() {
    margin-left: -$app-padding-mobile;
    margin-right: -$app-padding-mobile;
    width: calc(100% + #{$app-padding-mobile * 2});
  }
}
