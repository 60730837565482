@use '../../assets/css/deps' as *;

.wrapper {
  padding: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.icon {
  padding-bottom: 24px;
}

.text {
  line-height: 22px;
  text-align: center;
  word-break: break-word;
}
