.tile {
  width: 235px;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  flex-grow: 1;
  border: 1px solid var(--color-border-primary);
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease-out;

  &:hover {
    background-color: var(--color-background-disabled);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
  }
}

.preview {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  img {
    height: 50px;
  }
}
