@use '../../../../assets/css/deps' as *;
@use '../page';

.grid {
  // contain: content;
  grid-area: viewer;
  z-index: page.$viewer-box-z-index - 1;

  @if page.$debug-page {
    background-color: $debug-color;
  }
}
