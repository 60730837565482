@use '../../../../../assets/css/deps' as *;

.box {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);

  @include media_interval($media-desktop, 0) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.controls {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @include media_interval($media-desktop, 0) {
    grid-column: 2 / 3;
  }
}
