@use '../../assets/css/deps' as *;

.controlPanelButton {
  border: 2px solid #{$neutral-darkest} !important;
}

.controlPanelInputButton,
.controlPanelMoveButton,
.controlPanelCloseButton,
.controlPanelStyleGuidLink {
  width: auto !important;
  flex-shrink: 1;
}

.controlPanelButton:hover {
  border: 2px solid #{$dark} !important;
}

.controlPanelInputButton,
.controlPanelMoveButton,
.controlPanelStyleGuidLink {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.controlPanelCloseButton {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.topButtonsContainer {
  position: absolute;
  right: 10px;
  top: 3px;
  width: 80%;
  display: flex;
  gap: 5px;
}

.controlPanelMoveButton {
  display: flex;
  gap: 5px;
}

.bottomButtonsContainer {
  position: relative;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  gap: 3px;
  flex-wrap: wrap;

  & > button {
    width: 49%;
    padding: 0;
    margin: 0;
  }
}

.controlPanelStyleGuidLink {
  margin: auto;
  font-weight: 600;
  color: #{$primary-color} !important;
  text-decoration: underline solid;
  border: none !important;
}

.controlPanelStyleGuidLink:hover {
  color: #{$primary-color-hover} !important;
  background-color: transparent !important;
}

.controlPanelStyleGuidLink:active {
  color: #{$primary-color-active} !important;
  background-color: transparent !important;
}

// messages
.message {
  width: 100%;
  padding: 5px 10px;
  text-align: center;
  border-radius: #{rem(3)};
}

.updateMessage {
  color: #{$dark};
  background-color: #{$warning-color};
  border: 1px solid #{$dark};
}

// position
.left {
  left: 0;
  right: auto;

  .leftArrow {
    display: none;
  }
}

.right {
  left: auto;
  right: 0;

  .rightArrow {
    display: none;
  }
}

.arrow,
.arrow {
  font-size: 20px;
}
