@use '../../../../assets/css/deps' as *;
@use '../page';

.box {
  position: absolute;
  width: 100%;
  height: 100%;
  @include rmd-theme(background-color, surface);
}

.expanded {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100vw;
  height: 100vh;
  z-index: page.$viewer-box-z-index;
  @include rmd-utils-full-screen;
  //-webkit-transform: translate3d(0, 0, 0);

  @if page.$debug-page {
    opacity: 0.8;
  }
}

.inner {
  overflow: hidden;
  position: relative;
  border-radius: var(--shape-border-radius-base);
  height: 100%;
}

// visualizer
:global(.visualizer) {
  width: 100%;
  height: 100%;
  outline: none;
  @include rmd-theme(background-color, background);
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}

.box:global(.visualizer) > canvas {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
