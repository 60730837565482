@use '../../assets/css/deps' as *;

$color-indicator-offset: rem(5);
$color-indicator-size: rem(16);

.list {
  padding: 0;
}

.item {
}

.box:global(.rmd-toggle) {
  width: auto;
  height: auto;
  border-radius: var(--shape-border-radius-base);
  border: 1px solid var(--color-border-primary);
  min-width: 104px;
  transition: background-color 0.1s;

  &.checked,
  &:hover {
    background-color: var(--color-background-secondary);
  }

  label {
    padding: 11px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    font-size: var(--input-font-size);
    @include letter-spacing;
  }

  &:not(.checked) label:hover {
    cursor: pointer;
  }
}

.indicator {
  display: inline-block;
  width: $color-indicator-size;
  height: $color-indicator-size;
  margin-right: $color-indicator-offset;
  border-radius: 50%;
  border: 1px solid var(--color-border-primary);
  background-clip: content-box;
}
