@use '../../../assets/css/deps' as *;

.box {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.table {
  margin-left: -20px;
  margin-right: -20px;
  min-height: 200px;
}

.head {
  .label {
    text-transform: capitalize;
  }
}

.row {
  &:first-of-type {
    td {
      border-top: 1px solid var(--color-border-primary);
    }
  }

  td {
    padding: 12px 15px;
  }

  td:last-of-type {
    padding-right: 20px;
  }

  &:hover {
    background-color: var(--color-ui-primary-subtlest);
    cursor: pointer;
  }
}

.current {
  background-color: var(--color-ui-primary-subtlest);
}

.cell {
  white-space: nowrap;
  width: 1%;
}

.value {
  &:empty {
    &:before {
      content: '-';
      display: inline;
    }
  }
}

.divider {
  &:global(.rmd-divider) {
    margin-top: 8px;
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}
