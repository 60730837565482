@use '../../../../../assets/css/deps' as *;

.box {
}

.group {
  margin-bottom: 16px;
}

.option {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.widget {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
}

.note {
  margin-top: 5px;
}

.subprice {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}

.color {
  margin-top: 13px;
}

// counter
.counterHolder {
  display: flex;
  align-items: center;
}

.counterLabel {
  display: flex;
  flex-direction: column;
  word-break: break-word;
}

.counter {
  &:global(.rmd-text-field-container) {
    @include rmd-form-theme-update-var(text-height, rem(30));
  }

  :global(.rmd-button) {
    height: 30px;
  }
}

.price {
  white-space: nowrap;
}

.pricing {
  text-align: end;
  width: 30%;
}
