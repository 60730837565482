@use '../../assets/css/deps' as *;

.box {
  &:global(.rmd-typography) {
    color: var(--tbd-badge-color, $yellow-darkest);
  }
  padding: 5px;
  border-radius: var(--shape-border-radius-base);
  background-color: var(--tbd-badge-color-background, rmd-theme-var(warning));
  word-break: break-word;
  pointer-events: auto;
}
