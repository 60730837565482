// For color selector on order screen

// todo move to ColorSelect
#cart_page-specification_field-color_select-listbox {
  position: relative !important;
  top: auto !important;
  bottom: auto !important;
  left: 0 !important;
  width: 100% !important;
  max-height: 400px !important;

  .rmd-list-item--large {
    min-height: 0;
    height: 38px;
  }
}
