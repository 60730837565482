.description {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 3;
  background: var(--color-background-secondary);
  border-radius: var(--shape-border-radius-base);
  transition: 0.3s all ease-in-out;

  ul {
    padding-left: 20px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: var(--font-size-body-sm);
    list-style-type: disc;
  }

  p {
    padding: 13px 20px;
    font-size: var(--font-size-body-sm);
  }
}

.description:hover {
  opacity: 40%;
}
