@use '../../../../../assets/css/deps' as *;

.box {
}

.list {
  margin-top: $app-padding;
  margin-bottom: 0;
  padding-left: 0;
  grid-auto-flow: dense;
  grid-template-columns: auto 1fr;
}

.field {
}
