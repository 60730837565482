@use '../../../../assets/css/deps' as *;

// thumb
.box {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: $white; // thumbnail was rendered on white bg
}

.img {
  width: 100%;
  height: 100%;
  object-fit: none;
  object-position: center center;
}

// preloader
.inner {
  @include rmd-utils-absolute-centered;
  text-align: center;
}
